import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const RandomBlogCards = () => {
  const [randomCards, setRandomCards] = useState([]);

  useEffect(() => {
    axios.get(`${baseURL}/api/blogcards`)
      .then(response => {
        const selectedCards = selectRandomCards(response.data);
        setRandomCards(selectedCards);
      })
      .catch(error => console.error('Error fetching blog cards:', error));
  }, []);

  const selectRandomCards = (cards) => {
    const shuffled = [...cards].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  return (
    <div className='random_blog_cards'>
      <p>Цікаве та корисне</p>
      <div className="random_blog_cards_container">
        {randomCards.map(card => (
          <a key={card._id} href={`/blog/${card.pageId}`} className="blog_card">
            <img src={`${baseURL}${card.image}`} alt="Blog" />
            <p className="card_blog-title">{card.text}</p>
          </a>
        ))}
      </div>
      <Link to='/blog' className="all_blog">Дивитись всі статті</Link>
    </div>
  );
};

export default RandomBlogCards;
