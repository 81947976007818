import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const IntCardDelete = () => {
  const [selectedCardId, setSelectedCardId] = useState('');
  const [cards, setCards] = useState([]);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    axios.get(`${baseURL}/api/int-slider-cards`)
      .then(response => setCards(response.data))
      .catch(error => console.error(error));
  }, []);

  const handleDelete = () => {
    axios.delete(`${baseURL}/api/int-slider-cards/${selectedCardId}`)
      .then(response => {
        setCards(cards.filter(card => card._id !== selectedCardId));
        setSelectedCardId('');
        setNotification('Картку видалено успішно');
        setTimeout(() => setNotification(null), 3000);
      })
      .catch(error => {
        console.error('Error deleting card:', error);
        alert('Не вдалося видалити картку');
      });
  };

  return (
    <div className='container_create-slide-item'>
      <label className='label_text'>Виберіть картку для видалення:</label>
      <select className='select_delete-card' value={selectedCardId} onChange={(e) => setSelectedCardId(e.target.value)}>
        <option value="">Виберіть картку</option>
        {cards.map(card => (
          <option key={card._id} value={card._id}>{card.productName}</option>
        ))}
      </select>
      <button className='delete_card-slide' onClick={handleDelete} disabled={!selectedCardId}>Видалити картку</button>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </div>
  );
};

export default IntCardDelete;