import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const EditCard = () => {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/cards`)
      .then(response => {
        setCards(response.data);
      })
      .catch(error => console.error(error));
  }, []);

  const handleCardSelection = (e) => {
    const cardId = e.target.value;
    setSelectedCard(cardId);

    const selected = cards.find(card => card._id === cardId);
    if (selected) {
      setTitle(selected.title);
      setPrice(selected.price);
    }
  };

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (image) formData.append('image', image);
    formData.append('title', title);
    formData.append('price', price);

    try {
      await axios.put(`${baseURL}/api/cards/${selectedCard}`, formData);
      setNotification('Картку оновлено успішно!');
      setImage(null);
      setTitle('');
      setPrice('');
      setSelectedCard('');
    } catch (error) {
      console.error('Error updating card:', error);
      setNotification('Помилка оновлення картки!');
    }
  };

  return (
    <div>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
      <form className='card_form' onSubmit={handleSubmit}>
        <p>Редагувати картку продуктів</p>
        <select value={selectedCard} onChange={handleCardSelection} required>
          <option value="">Виберіть картку для редагування</option>
          {cards.map(card => (
            <option key={card._id} value={card._id}>
              {card.title}
            </option>
          ))}
        </select>
        <input type="file" onChange={handleImageUpload} />
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Назва"
          required
        />
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Ціна"
          required
        />
        <button type="submit">Оновити картку</button>
      </form>
    </div>
  );
};

export default EditCard;