import React, { useState } from 'react';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CreateCategoryCard = () => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [notification, setNotification] = useState('');

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', image);
    formData.append('title', title);
    formData.append('link', link);

    try {
      const response = await fetch(`${baseURL}/api/category-cards`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      setImage(null);
      setTitle('');
      setLink('');

      setNotification('Категорія успішно створена!');

      setTimeout(() => {
        setNotification('');
      }, 3000);

    } catch (error) {
      console.error('Error creating category card:', error);
      setNotification('Помилка при створенні категорії');
    }
  };

  return (
    <form className='catalog_form' onSubmit={handleSubmit}>
      <p>Завантажити картинку</p>
      <input type="file" accept="image/*" onChange={handleImageChange} required />
      <p>Назва категорії</p>
      <input type="text" value={title} onChange={handleTitleChange} required />
      <p>ID категорії (наприклад 1 або назва категорії англійською)</p>
      <input type="text" value={link} onChange={handleLinkChange} required />
      <button type="submit">Створити картку</button>

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </form>
  );
};

export default CreateCategoryCard;