import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PlusIcon from '../../Icon/plus-input.svg';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const AddCheckboxes = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [filterGroupsBySubcategory, setFilterGroupsBySubcategory] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newCheckboxes, setNewCheckboxes] = useState(['']);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/podcategory`);
        setSubcategories(response.data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };
    fetchSubcategories();
  }, []);

  useEffect(() => {
    const fetchFilterGroups = async () => {
      if (selectedSubcategory) {
        try {
          const response = await axios.get(`${baseURL}/api/filters/${selectedSubcategory}`);
          setFilterGroupsBySubcategory(response.data);
        } catch (error) {
          console.error('Error fetching filter groups:', error);
        }
      }
    };
    fetchFilterGroups();
  }, [selectedSubcategory]);

  const handleNewCheckboxChange = (index, value) => {
    const updatedNewCheckboxes = [...newCheckboxes];
    updatedNewCheckboxes[index] = value;
    setNewCheckboxes(updatedNewCheckboxes);
  };

  const addNewCheckbox = () => {
    setNewCheckboxes(prev => [...prev, '']);
  };

  const handleAddNewCheckboxes = async () => {
    if (!selectedGroup || newCheckboxes.every(c => !c.trim())) {
      setNotification('Оберіть групу фільтрів і заповніть нові чекбокси');
      return;
    }

    try {
      await axios.put(`${baseURL}/api/filters/${selectedGroup}`, { checkboxes: newCheckboxes });
      setNewCheckboxes(['']);
      setNotification('Чекбокси успішно додані');
    } catch (error) {
      console.error('Error adding new checkboxes:', error);
      setNotification('Помилка при додаванні чекбоксів');
    }
  };

  return (
    <div className='add_filter'>
      <h3>Додати нові чекбокси</h3>
      <select value={selectedSubcategory} onChange={(e) => setSelectedSubcategory(e.target.value)}>
        <option value="">Оберіть підкатегорію</option>
        {subcategories.map(subcategory => (
          <option key={subcategory._id} value={subcategory._id}>{subcategory.name}</option>
        ))}
      </select>
      <select value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)}>
        <option value="">Оберіть групу фільтрів</option>
        {filterGroupsBySubcategory.map(group => (
          <option key={group._id} value={group._id}>{group.groupName}</option>
        ))}
      </select>
      {selectedGroup && (
        <div className='add-check'>
          {newCheckboxes.map((checkbox, index) => (
            <div className='inner_add-check' key={index}>
              <input
                type="text"
                value={checkbox}
                onChange={(e) => handleNewCheckboxChange(index, e.target.value)}
                placeholder={`Чекбокс ${index + 1}`}
              />
              {index === newCheckboxes.length - 1 && (
                <button onClick={addNewCheckbox}><img src={PlusIcon} alt="Add Checkbox"/></button>
              )}
            </div>
          ))}
          <button className='add_check-btn__btn' onClick={handleAddNewCheckboxes}>Додати</button>
        </div>
      )}

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </div>
  );
};

export default AddCheckboxes;