import React, { useState, useEffect } from 'react';
import axios from 'axios';

import '../css-page/css-page.css';

import Nav from '../components/js-components/Nav';
import Footer from '../components/js-components/Footer';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const Blogpage = () => {
  const [blogCards, setBlogCards] = useState([]);

  useEffect(() => {
    axios.get(`${baseURL}/api/blogcards`)
      .then(response => setBlogCards(response.data))
      .catch(error => console.error('Error fetching blog cards:', error));
  }, []);

  return (
    <div className='content_container'>
      <Nav />
      <h1 className='blog_title'>Цікаве та корисне</h1>
      <div className="grid_item-card">
        {blogCards.map(card => (
          <a key={card._id} href={`${baseURL}/blog/${card.pageId}`} className="blog_card">
            <img src={`${baseURL}${card.image}`} alt="Blog" />
            <p className="card_blog-title">{card.text}</p>
          </a>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Blogpage;
