import React from 'react';

import '../css-page/css-page.css'

import Nav from '../components/js-components/Nav'
import Footer from '../components/js-components/Footer'

import TopSlider from '../page/slider/TopSlider'
import IntSlider from '../page/slider/IntSlider'
import RecSlider from '../page/slider/RecSlider'
import SliderLine from '../page/slider-line/SliderLine'

import RandomBlogCards from '../components/js-components/RandomBlogCards';

import HeroSlider from '../page/slider/HeroSlider'

const Homepage = () => {
  return (
    <div className='content_container'>
        <Nav />
        <HeroSlider />
        <SliderLine />
        <TopSlider />
        <IntSlider />
        <RecSlider />
        <RandomBlogCards />
        <Footer />
    </div>
  );
};

export default Homepage;