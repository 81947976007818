import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import '../css-page/css-page.css';

import Nav from '../components/js-components/Nav';
import Footer from '../components/js-components/Footer';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const BlogPageTemplate = () => {
  const { id } = useParams();
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await fetch(`${baseURL}/api/blogpages/${id}`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          setPageData(data);
        } else {
          const text = await response.text();
          setError('Unexpected response format');
        }
      } catch (error) {
        setError('Failed to fetch blog page data');
      }
    };

    fetchPageData();
  }, [id]);

  if (error) {
    return <div>Помилка: {error}</div>;
  }

  if (!pageData) {
    return <div>Завантаження...</div>;
  }

  return (
    <div className='content_container'>
      <Nav />
      <h1 className='blog_title-page'>{pageData.title}</h1>
      <div className="blog_page-content">
        {pageData.images.map((image, index) => (
          <img key={index} src={`${baseURL}/${image}`} alt={`Blog Image ${index + 1}`} />
        ))}
      </div>
      <div className="blog_descr-text">
        <p className='page_descr-text'>{pageData.description}</p>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPageTemplate;
