import React from 'react';

import '../css-page/css-page.css'

import Nav from '../components/js-components/Nav'
import Footer from '../components/js-components/Footer'

import ImageBlog from '../product-image/1.png'

const InnerBlogPage = () => {
  return (
    <div className='content_container'>
        <Nav />
        <h1 className='blog_title-page'>Цікаве та корисне</h1>
        <div className="blog_page-content">
          <img src={ImageBlog} alt="" />
          <img src={ImageBlog} alt="" />
          <img src={ImageBlog} alt="" />
        </div>
        <div className="blog_descr-text">
          <p className='page_descr-text'></p>
        </div>
        <Footer />
    </div>
  );
};

export default InnerBlogPage;