import React from 'react';
import { useLocation } from 'react-router-dom';

import Nav from '../components/js-components/Nav';
import Footer from '../components/js-components/Footer';

const generateOrderNumber = () => {
  const randomDigits = () => Math.random().toString().substr(2, 4);
  return `${randomDigits()}-${randomDigits()}-${randomDigits()}-${randomDigits()}`;
};

const formatPrice = (price) => {
  return new Intl.NumberFormat('uk-UA').format(price);
};

const ThankYouPage = () => {
  const location = useLocation();
  const { orderData } = location.state || {};
  const orderNumber = generateOrderNumber();
  const totalAmount = orderData.cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  return ( 
    <div className="content_container">
      <Nav />
      <div className="inner__page-th">
        <h1>Ваше замовлення успішно оформлене</h1>
        <p className='order_number'>Номер замовлення: {orderNumber}</p>

        <div className="user_info_order">
            <h2>Ваші дані</h2>
            <p><strong>Ім'я та прізвище:</strong> {orderData.name}</p>
            <p><strong>Телефон:</strong> {orderData.phone}</p>
            <p><strong>Спосіб доставки:</strong> {orderData.deliveryMethod}</p>
            <p><strong>Адреса відділення:</strong> {orderData.address}</p>
            <p><strong>Номер відділення або індекс:</strong> {orderData.postalCode}</p> 
        </div>

        <div className="user_info-order_bottom">
            <h3>Замовлення</h3>
            <ul>
                {orderData.cartItems.map(item => (
                <li key={item.id}>
                    <img src={item.image} alt={item.name} style={{ width: '80px', height: '80px', marginBottom: '20px' }} />
                    <p>{item.name}: {item.quantity} шт. {formatPrice(item.price)} грн.</p>
                </li>
                ))}
            </ul>
        </div>

        <p className='all_order-price'><strong>Загальна сума до оплати:</strong> {formatPrice(totalAmount)} грн.</p>

        <p className='th'>Дякуємо за замовлення, невдовзі наші оператори з вами зв'яжуться!</p>
      </div>
      <Footer />
    </div>
  );
};

export default ThankYouPage;