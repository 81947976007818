import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const FilterSidebar = ({ subcategoryId, onApplyFilters }) => {
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (subcategoryId) {
      axios.get(`${baseURL}/api/filters/${subcategoryId}`)
        .then(response => {
          const formattedFilters = response.data.reduce((acc, group) => {
            acc[group.groupName] = group.checkboxes;
            return acc;
          }, {});
          setFilters(formattedFilters);
          setSelectedFilters({});
        })
        .catch(error => console.error('Error loading filters:', error));
    }
  }, [subcategoryId]);

  const handleCheckboxChange = (group, checkbox) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [group]: {
        ...prevState[group],
        [checkbox]: !prevState[group]?.[checkbox],
      },
    }));
  };

  const handleApply = () => {
    setShowSidebar(false);
    onApplyFilters(selectedFilters);
  };

  const handleReset = () => {
    setSelectedFilters({});
    setShowSidebar(false);
    onApplyFilters({});
  };

  return (
    <>
      <button className="open-sidebar-btn" onClick={() => setShowSidebar(prev => !prev)}>Фільтр</button>
      <div className={`sidebar ${showSidebar ? 'sidebar-open' : ''}`}>
        <div className="checkbox-groups">
          {Object.entries(filters).map(([group, checkboxes]) => (
            <div key={group}>
              <h3>{group}</h3>
              {(Array.isArray(checkboxes) ? checkboxes : []).map(checkbox => (
                <label key={checkbox}>
                  <input
                    type="checkbox"
                    checked={!!selectedFilters[group]?.[checkbox]}
                    onChange={() => handleCheckboxChange(group, checkbox)}
                  />
                  {checkbox}
                </label>
              ))}
            </div>
          ))}
        </div>
        <div className="filter-actions">
          <button className="apply-btn" onClick={handleApply}>Застосувати</button>
          <button className="reset-btn" onClick={handleReset}>Скинути</button>
        </div>
      </div>
    </>
  );
};

export default FilterSidebar;