import React, { useState, useEffect } from 'react';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const EditCategoryCard = () => {
  const [cards, setCards] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState('');
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await fetch(`${baseURL}/api/category-cards`);
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error('Error fetching category cards:', error);
      }
    };

    fetchCards();
  }, []);

  useEffect(() => {
    if (selectedCardId) {
      const fetchCardData = async () => {
        try {
          const response = await fetch(`${baseURL}/api/category-cards/${selectedCardId}`);
          const data = await response.json();
          setTitle(data.title);
        } catch (error) {
          console.error('Error fetching category card data:', error);
        }
      };

      fetchCardData();
    }
  }, [selectedCardId]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleCardChange = (e) => {
    setSelectedCardId(e.target.value);
    setImage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (image) {
      formData.append('image', image);
    }
    formData.append('title', title);

    try {
      const response = await fetch(`${baseURL}/api/category-cards/${selectedCardId}`, {
        method: 'PUT',
        body: formData,
      });
      const data = await response.json();

      setImage(null);
      setTitle('');

      setNotification('Категорію успішно оновлено!');

      setTimeout(() => {
        setNotification('');
      }, 3000);

    } catch (error) {
      console.error('Error updating category card:', error);
      setNotification('Помилка при оновленні категорії');
    }
  };

  const handleDelete = async () => {
    if (!selectedCardId) return;

    try {
      const response = await fetch(`${baseURL}/api/category-cards/${selectedCardId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setNotification('Категорію успішно видалено!');
        setCards(cards.filter(card => card._id !== selectedCardId));
        setSelectedCardId('');
        setTitle('');
        setImage(null);

        setTimeout(() => {
          setNotification('');
        }, 3000);
      } else {
        throw new Error('Error deleting category card');
      }
    } catch (error) {
      console.error('Error deleting category card:', error);
      setNotification('Помилка при видаленні категорії');
    }
  };

  return (
    <form className='catalog_form' onSubmit={handleSubmit}>
      <p>Список категорій</p>
      <select value={selectedCardId} onChange={handleCardChange} required>
        <option value="" disabled>Оберіть картку</option>
        {cards.map((card) => (
          <option key={card._id} value={card._id}>
            {card.title}
          </option>
        ))}
      </select>
      <p>Оновити картинку</p>
      <input type="file" accept="image/*" onChange={handleImageChange} disabled={!selectedCardId} />
      <p>Оновити ID (якщо потрібно)</p>
      <input
        type="text"
        value={title}
        onChange={handleTitleChange}
        placeholder="Назва"
        required
        disabled={!selectedCardId}
      />
      <button type="submit" disabled={!selectedCardId}>Оновити картку</button>
      <button type="button" onClick={handleDelete} disabled={!selectedCardId} >
        Видалити картку
      </button>

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </form>
  );
};

export default EditCategoryCard;