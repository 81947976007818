import React, { useState } from 'react';
import IntCardCreate from './IntCardCreate';
import IntCardEdit from './IntCardEdit';
import IntCardDelete from './IntCardDelete';

const SliderCardManager = () => {
  const [selectedTab, setSelectedTab] = useState('create');

  return (
    <div className="slider-card-manager">
      <div className="tabs_line">
        <button className='tab_btn_slider' onClick={() => setSelectedTab('create')}>Створити картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('edit')}>Редагувати картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('delete')}>Видалити картку</button>
      </div>
      
      <div className="content_slider-create">
        {selectedTab === 'create' && <IntCardCreate />}
        {selectedTab === 'edit' && <IntCardEdit />}
        {selectedTab === 'delete' && <IntCardDelete />}
      </div>
    </div>
  );
};

export default SliderCardManager;