import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import Nav from '../components/js-components/Nav';
import Footer from '../components/js-components/Footer';
import FilterSidebar from '../page/create-filter/FilterSidebar';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const PodCategoryTemplate = () => {
  const { id } = useParams();
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [categoryName, setCategoryName] = useState('');
  const [subcategoryId, setSubcategoryId] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/cards?podCategory=${id}`)
      .then(response => {
        setCards(response.data);
        setFilteredCards(response.data);
      })
      .catch(error => console.error('Error fetching cards:', error));
  
    axios.get(`${baseURL}/api/podcategory/${id}`)
      .then(response => {
        setCategoryName(response.data.name);
        setSubcategoryId(response.data._id);
      })
      .catch(error => console.error('Error fetching category:', error));
  }, [id]);

  const applyFilters = (selectedFilters) => {
    let filtered = [...cards];
    
    if (Object.keys(selectedFilters).length > 0) {
      filtered = cards.filter(card => {
        if (!card.characteristics) {
          console.log('No characteristics for card:', card);
          return false;
        }
    
        const characteristicsArray = card.characteristics.split(/\s+/).map(char => char.toLowerCase());
    
        return Object.entries(selectedFilters).some(([group, checkboxes]) => {
          return Object.keys(checkboxes).some(checkbox => {
            const checkboxLower = checkbox.trim().toLowerCase();
            return characteristicsArray.includes(checkboxLower);
          });
        });
      });
    }
    setFilteredCards(filtered);
  };

  return (
    <div className='content_container'>
      <Nav />
      <div className="category_pod-container">
        <div className="top__pod">
          <p>{categoryName}</p>
          <FilterSidebar 
            subcategoryId={subcategoryId}
            productCards={cards}
            onApplyFilters={applyFilters}
          />
        </div>
        <div className="grid_pod">
          {
            filteredCards.map(card => (
              <Link key={card._id} to={`/product/${card.page}`} className="card_pod">
                <img src={`${baseURL}/${card.image || 'default-image.jpg'}`} alt={card.title} className="card_pod-img" />
                <div className="inner_pod-card">
                  <p className='card_pod-title'>{card.title}</p>
                  <span>В наявності<br />Готовий до відправки</span>
                  <p className='card_pod-price'>{card.price} ₴</p>
                  <div className="card-characteristics">
                    {card.characteristics && card.characteristics.split(/\s+/).map((char, index) => (
                      <p key={index} className="hidden-characteristic">{char}</p>
                    ))}
                  </div>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PodCategoryTemplate;