import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import Nav from '../js-components/Nav';
import Footer from '../js-components/Footer';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const SearchPage = () => {
  const [cards, setCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const query = new URLSearchParams(useLocation().search).get('query');

  useEffect(() => {
    axios.get(`${baseURL}/api/cards`)
      .then(response => {
        setCards(response.data);
        filterCards(response.data, query);
      })
      .catch(error => console.error('Error fetching cards:', error));
  }, [query]);

  const filterCards = (cards, query) => {
    if (query) {
      const lowerCaseQuery = query.toLowerCase();
      const filtered = cards.filter(card =>
        card.title.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredCards(filtered);
    } else {
      setFilteredCards(cards);
    }
  };

  return (
    <div className='content_container'>
      <Nav />
      <div className="search-results-container">
        <p className='search_title'>Результати пошуку</p>
        {filteredCards.length > 0 ? (
          <div className="grid_pod">
            {filteredCards.map(card => (
              <Link key={card._id} to={`/product/${card.page}`} className="card_pod">
                <img src={card.image ? `/${card.image}` : '/default-image.jpg'} alt={card.title} className="card_pod-img" />
                <div className="inner_pod-card">
                  <p className='card_pod-title'>{card.title}</p>
                  <span>В наявності<br />Готовий до відправки</span>
                  <p className='card_pod-price'>{card.price} ₴</p>
                  <div className="card-characteristics">
                    {card.characteristics && card.characteristics.split(/\s+/).map((char, index) => (
                      <p key={index} className="hidden-characteristic">{char}</p>
                    ))}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <p>Результати відсутні</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SearchPage;