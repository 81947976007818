import React, { useState } from 'react';

import NewReviews from '../new-review/NewReview'
import ManageReviews from '../new-review/ManageReviews'

const TabRev = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const renderContent = () => {
    switch (activeTab) {
      case 'tab1':
        return <div><NewReviews /></div>;
      case 'tab2':
        return <div><ManageReviews /></div>;
      default:
        return <div>Виберіть таб для перегляду контенту</div>;
    }
  };

  return (
    <div className="tab_container-adm">
      <div className="tab_buttons-adm">
        <button onClick={() => setActiveTab('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>Нові відгуки</button>
        <button onClick={() => setActiveTab('tab2')} className={activeTab === 'tab2' ? 'active' : ''}>Керування відгуками</button>
      </div>
      <div className="tab_content-adm">
        {renderContent()}
      </div>
    </div>
  );
};

export default TabRev;