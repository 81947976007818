import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const ManageReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/reviews`)
      .then(response => {
        setReviews(response.data);
      })
      .catch(error => {
        console.error('Помилка при завантаженні відгуків:', error);
      });
  }, []);

  const handleDelete = (id) => {
    axios.delete(`${baseURL}/api/reviews/${id}`)
      .then(() => {
        setReviews(prevReviews => prevReviews.filter(review => review._id !== id));
        setNotification('Відгук успішно видалено!');
        setTimeout(() => setNotification(''), 3000);
      })
      .catch(error => {
        console.error('Помилка при видаленні відгуку:', error);
        setNotification('Не вдалося видалити відгук');
        setTimeout(() => setNotification(''), 3000);
      });
  };

  return (
    <div>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
      <div className="manage_reviews_container">
        {reviews.map((review, index) => (
          <div key={index} className="review_card-rev">
            <p className="product_title-rev">{review.productId.name}</p>
            <div className='rev__date-user'>
              <p className="review_user-rev">{review.name}</p>
              <p className="review_date-rev">Дата: {review.date}</p>
            </div>
            <p className="review_text-rev">{review.text}</p>
            <button onClick={() => handleDelete(review._id)}>Видалити</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageReviews;