import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const IntCardCreate = () => {
  const [image, setImage] = useState(null);
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [productPage, setProductPage] = useState('');
  const [pages, setPages] = useState([]);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    axios.get(`${baseURL}/api/products`)
      .then(response => setPages(response.data))
      .catch(error => console.error(error));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('image', image);
    formData.append('productName', productName);
    formData.append('price', price);
    formData.append('productPage', productPage);

    axios.post(`${baseURL}/api/int-slider-cards`, formData)
      .then(response => {
        setNotification('Картку створено успішно');
        setTimeout(() => setNotification(null), 3000);
      })
      .catch(error => console.error(error));
  };

  return (
    <div className='container_create-slide-item'>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Зображення продукту:</label>
          <input type="file" onChange={(e) => setImage(e.target.files[0])} />
        </div>
        <div>
          <label>Назва продукту:</label>
          <input type="text" value={productName} onChange={(e) => setProductName(e.target.value)} />
        </div>
        <div>
          <label>Ціна продукту:</label>
          <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
        </div>
        <div>
          <label>Сторінка продукту:</label>
          <select value={productPage} onChange={(e) => setProductPage(e.target.value)}>
            <option value="">Виберіть сторінку продукту</option>
            {pages.map(page => (
              <option key={page._id} value={page._id}>{page.name}</option>
            ))}
          </select>
        </div>
        <button type="submit">Створити картку</button>
      </form>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </div>
  );
};

export default IntCardCreate;