import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import leftArrow from "../../Icon/left.svg";
import rightArrow from "../../Icon/right.svg";

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CustomNextArrow = ({ onClick }) => (
  <div className="arrow_btn next" onClick={onClick}>
    <img src={rightArrow} alt="Next" />
  </div>
);

const CustomPrevArrow = ({ onClick }) => (
  <div className="arrow_btn prev" onClick={onClick}>
    <img src={leftArrow} alt="Previous" />
  </div>
);

const HeroSlider = () => {
  const [slides, setSlides] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    axios.get(`${baseURL}/api/hero-slides`)
      .then(response => {
        setSlides(response.data);
      })
      .catch(error => console.error("Error fetching slides:", error));
  }, []);

  useEffect(() => {
    const slider = sliderRef.current;

    const handleWheel = (e) => {
      e.preventDefault();
      slider.scrollLeft += e.deltaY > 0 ? -100 : 100;
    };

    if (slider) {
      slider.addEventListener("wheel", handleWheel);

      return () => {
        slider.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div className="slider_container" ref={sliderRef} style={{ width: "100%", minHeight: "200px", maxHeight: "400px" }}>
      <Slider {...settings}>
        {slides.map((slide) => (
          <Link key={slide._id} to={`/podcategory/${slide.categoryPageId._id}`}>
            <img src={`${baseURL}${slide.url}`} alt={`Slide ${slide._id}`} />
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSlider;
