import React, { useState } from 'react';

import PhoneInput from '../contact-edit/AdminPhoneInput'
import AdminFooterLinks from '../contact-edit/AdminFooterLinks'

const TabContact = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const renderContent = () => {
    switch (activeTab) {
      case 'tab1':
        return <div><PhoneInput /></div>;
      case 'tab2':
        return <div><AdminFooterLinks /></div>;
      default:
        return <div>Виберіть таб для перегляду контенту</div>;
    }
  };

  return (
    <div className="tab_container-adm">
      <div className="tab_buttons-adm">
        <button onClick={() => setActiveTab('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>Додати телефон</button>
        <button onClick={() => setActiveTab('tab2')} className={activeTab === 'tab2' ? 'active' : ''}>Додати соціальні мережі</button>
      </div>
      <div className="tab_content-adm">
        {renderContent()}
      </div>
    </div>
  );
};

export default TabContact;