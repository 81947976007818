import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TabMain from './adm-tab/TabMain';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const AdminPanel = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${baseURL}/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (data.success) {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
      } else {
        setError('Неправильний логін або пароль');
      }
    } catch (err) {
      setError('Щось пішло не так, спробуйте ще раз пізніше');
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUsername('');
    setPassword('');
    localStorage.removeItem('isAuthenticated');
    navigate('/');
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('uk-UA', options);
  };

  const formatTime = (date) => {
    const options = { hour: '2-digit', minute: '2-digit' };
    return date.toLocaleTimeString('uk-UA', options);
  };

  return (
    <div className='content_container'>
      {isAuthenticated ? (
        <>
          <div className="top_panel-adm">
            <h1>Панель керування</h1>
            <button onClick={handleLogout}>Вихід</button>
          </div>
          <div className='data_time'>
            <p>{formatDate(currentTime)}</p>
            <p>{formatTime(currentTime)}</p>
          </div>
          <TabMain />
        </>
      ) : (
        <div className="login-form-container">
          <h2>Авторизація адміністратора</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="username">Логін:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Пароль:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="submit">Увійти</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;