import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CreateCard = () => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState('');
  const [podCategories, setPodCategories] = useState([]);
  const [selectedPodCategory, setSelectedPodCategory] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/products`)
      .then(response => setPages(response.data))
      .catch(error => console.error('Error fetching pages:', error));

    axios.get(`${baseURL}/api/podcategory`)
      .then(response => setPodCategories(response.data))
      .catch(error => console.error('Error fetching podcategories:', error));
  }, []);

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('image', image);
    formData.append('title', title);
    formData.append('price', price);
    formData.append('page', selectedPage);
    formData.append('podCategory', selectedPodCategory);
  
    try {
      const response = await axios.get(`${baseURL}/api/products/${selectedPage}`);
      const product = response.data;
  
      const characteristicsString = product.characteristics.right.join(' ');
      formData.append('characteristics', characteristicsString);
  
      await axios.post(`${baseURL}/api/cards`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setNotification('Картку створено успішно!');
      setImage(null);
      setTitle('');
      setPrice('');
      setSelectedPage('');
      setSelectedPodCategory('');
    } catch (error) {
      console.error('Error creating card:', error.response ? error.response.data : error.message);
      setNotification('Помилка при створенні картки!');
    }
  };

  return (
    <div>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
      <form className='card_form' onSubmit={handleSubmit}>
        <p>Створити картку продуктів</p>
        <input type="file" onChange={handleImageUpload} required />
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Назва"
          required
        />
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Ціна"
          required
        />
        <select value={selectedPage} onChange={(e) => setSelectedPage(e.target.value)} required>
          <option value="">Виберіть сторінку продукту</option>
          {pages.map(page => (
            <option key={page._id} value={page._id}>
              {page.name}
            </option>
          ))}
        </select>
        <select value={selectedPodCategory} onChange={(e) => setSelectedPodCategory(e.target.value)} required>
          <option value="">Виберіть підкатегорію</option>
          {podCategories.map(podCategory => (
            <option key={podCategory._id} value={podCategory._id}>
              {podCategory.name}
            </option>
          ))}
        </select>
        <button type="submit">Створити картку</button>
      </form>
    </div>
  );
};

export default CreateCard;