import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Отримання базового URL з змінних середовища
const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const ReviewForm = () => {
  const { id: productId } = useParams();
  const [reviews, setReviews] = useState([]);
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${baseURL}/api/reviews/${productId}`)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setReviews(data);
      })
      .catch((error) => console.error('Error fetching reviews:', error));
  }, [productId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const review = {
      productId,
      name,
      text,
      date: new Date().toLocaleDateString('uk-UA', { year: 'numeric', month: 'long', day: 'numeric' })
    };
  
    try {
      const response = await fetch(`${baseURL}/api/reviews`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(review)
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit review');
      }
  
      const newReview = await response.json();
      setReviews((prevReviews) => [newReview, ...prevReviews]);
      setName('');
      setText('');
      setNotification('Ваш відгук успішно залишено!');
      setShowForm(false);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setNotification('');
      }, 4000);
    } catch (error) {
      console.error('Error submitting review:', error);
      setError('Failed to submit review');
    }
  };

  return (
    <>
      <div className="rev_item">
        <p className="rev">Відгуки користувачів</p>
        <button className="rev_btn" onClick={() => setShowForm(!showForm)}>Написати відгук</button>
      </div>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
      {showForm && (
        <div className="rev_form">
          <form onSubmit={handleSubmit}>
            <p className='top'>Ім'я та прізвище</p>
            <input 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
            />
            <p className='bottom'>Ваш відгук</p>
            <textarea 
              value={text} 
              onChange={(e) => setText(e.target.value)} 
            />
            <button type="submit">Додати відгук</button>
          </form>
        </div>
      )}
      <div className="rev_text-user">
        {loading ? (
          <div className="dot-spinner">
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
          </div>
        ) : (
          reviews.map((review, index) => (
            <div className="rev_user-item" key={index}>
              <div className="rev_name-data">
                <p className="name_user-rev">{review.name}</p>
                <span className="data_rev">{review.date}</span>
              </div>
              <p className="text_user-rev">{review.text}</p>
            </div>
          ))
        )}
      </div>
      {error && <p className="error">{error}</p>}
    </>
  );
};

export default ReviewForm;