import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CartContext } from '../CartContext';
import Nav from '../components/js-components/Nav';
import Footer from '../components/js-components/Footer';
import DeleteIcon from '../Icon/trash.svg';
import MinusIcon from '../Icon/minus.svg';
import PlusIcon from '../Icon/plus.svg';
import '../css-page/css-page.css';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const OrderPage = () => {
  const { cartItems, removeFromCart, updateQuantity, clearCart } = useContext(CartContext);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserOrderInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get(`${baseURL}/api/user/order-info`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          if (response.data) {
            setName(response.data.name);
            setPhone(response.data.phone);
            setAddress(response.data.deliveryAddress);
            setPostalCode(response.data.deliveryIndex);
            setDeliveryMethod(response.data.deliveryMethod);
          }
        }
      } catch (error) {
        console.error('Не вдалося завантажити інформацію про користувача', error);
      }
    };

    fetchUserOrderInfo();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const date = new Date().toLocaleString();

    const orderData = {
        name,
        phone,
        address,
        postalCode,
        deliveryMethod,
        paymentMethod,
        cartItems: cartItems.map(item => ({
            name: item.name,
            image: item.image,
            price: item.price,
            quantity: item.quantity
        })),
        date
    };

    const existingOrders = JSON.parse(localStorage.getItem('orders')) || [];
    existingOrders.push(orderData);
    localStorage.setItem('orders', JSON.stringify(existingOrders));

    const message = `
<b>Заявка з сайту!</b>\n
<b>Ім'я та прізвище: </b>${name}
<b>Телефон: </b>${phone}\n
<b>Спосіб доставки: </b>${deliveryMethod}\n
<b>Адреса відділення: </b>${address}\n
<b>Номер відділення/індекс: </b>${postalCode}\n
<b>Метод оплати: </b>${paymentMethod}\n
<b>Замовлення:</b>\n${cartItems.map(item => `- ${item.name}: ${item.quantity} шт. ${item.price} грн.`).join('\n')}
`;

    const TOKEN = "6383541177:AAGEsa4B5Bsu_eeWuptQGy1cRGvjm28kwYY";
    const CHAT_ID = "-1002049716001";
    const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    axios.post(URI_API, {
      chat_id: CHAT_ID,
      parse_mode: 'html',
      text: message
    }).then(() => {
      clearCart();
      navigate('/thank-you', { state: { orderData } });
    }).catch(err => {
      console.error('Помилка при відправленні замовлення', err);
    });
  };

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('uk-UA').format(price);
  };

  return (
    <div className="content_container">
      <Nav />
      <div className="inner_order"> 
        <h1>Оформлення замовлення</h1>
        <div className="order-summary">
            <h2>Ваше замовлення:</h2>
            {cartItems.length === 0 ? (
            <p>Кошик порожній</p>
            ) : (
            <ul>
                {cartItems.map(item => (
                <li key={item.id} className="order-item">
                    <div className="img_price-delete">
                        <img className="order-item-image" src={item.image} alt={item.name} />
                        <p className="order-item-name">{item.name}</p>
                        <button onClick={() => removeFromCart(item.id)}><img src={DeleteIcon} alt="X" /></button>
                    </div>
                    <div className="count_proice--order">
                        <div className="order-item-controls">
                            <button onClick={() => updateQuantity(item.id, -1)}><img src={MinusIcon} alt="-" /></button>
                            <span>{item.quantity}</span>
                            <button onClick={() => updateQuantity(item.id, 1)}><img src={PlusIcon} alt="+" /></button>
                        </div>
                        <p className="order-item-price">{formatPrice(item.price * item.quantity)} грн.</p>
                    </div>
                </li>
                ))}
            </ul>
            )}
        </div>
        <form className='order__form' id="orderForm" onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="name">Ім'я:</label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="phone">Телефон:</label>
                <input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="address">Адреса відділення:</label>
                <input
                    type="text"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="postalCode">Номер відділення або індекс:</label>
                <input
                    type="text"
                    id="postalCode"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="deliveryMethod">Спосіб доставки:</label>
                <select
                    id="deliveryMethod"
                    value={deliveryMethod}
                    onChange={(e) => setDeliveryMethod(e.target.value)}
                    required
                >
                    <option value="">Оберіть спосіб доставки</option>
                    <option value="нова пошта відділення">Нова Пошта відділення</option>
                    <option value="нова пошта поштомат">Нова Пошта поштомат</option>
                    <option value="нова пошта кур'єрська доставка">Нова Пошта кур'єрська доставка</option>
                    <option value="укрпошта">Укрпошта</option>
                </select>
            </div>
            <div className="form-group">
                <p className='pay_title-text__check'>Метод оплати:</p>
                <div className='check'>
                    <input
                      type="radio"
                      id="paymentCOD"
                      value="оплата при отриманні"
                      checked={paymentMethod === 'оплата при отриманні'}
                      onChange={() => setPaymentMethod('оплата при отриманні')}
                    />
                    <label className='pay_metod' htmlFor="paymentCOD">Оплата при отриманні</label>
                </div>
                <div className='check'>
                    <input
                      type="radio"
                      id="paymentPrepaid"
                      value="Банківський переказ"
                      checked={paymentMethod === 'Банківський переказ'}
                      onChange={() => setPaymentMethod('Банківський переказ')}
                    />
                    <label className='pay_metod' htmlFor="paymentPrepaid">Банківський переказ</label>
                </div>
                <div className='check'>
                    <input
                      type="radio"
                      id="paymentRequisites"
                      value="Оплата за реквізитами"
                      checked={paymentMethod === 'Оплата за реквізитами'}
                      onChange={() => setPaymentMethod('Оплата за реквізитами')}
                    />
                    <label className='pay_metod' htmlFor="paymentRequisites">Оплата за реквізитами</label>
                </div>
            </div>
            <button type="submit" className="submit_btn">Підтвердити замовлення</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default OrderPage;