import React, { useState, useEffect } from "react";
import axios from "axios";

import DeleteIcon from '../../Icon/trash.svg'

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CreateSlideForm = () => {
  const [image, setImage] = useState(null);
  const [selectedPage, setSelectedPage] = useState("");
  const [podCategories, setPodCategories] = useState([]);
  const [slides, setSlides] = useState([]);
  const [notification, setNotification] = useState("");

  useEffect(() => {
    axios.get(`${baseURL}/api/podcategory`)
      .then(response => {
        setPodCategories(response.data);
      })
      .catch(error => console.error("Error fetching podcategories:", error));
    
    axios.get(`${baseURL}/api/hero-slides`)
      .then(response => {
        setSlides(response.data);
      })
      .catch(error => console.error("Error fetching slides:", error));
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handlePageChange = (e) => {
    setSelectedPage(e.target.value);
  };

  const handleCreateSlide = (e) => {
    e.preventDefault();

    if (!image || !selectedPage) {
      setNotification("Будь ласка, заповніть всі поля.");
      return;
    }

    const formData = new FormData();
    formData.append("image", image);
    formData.append("categoryPageId", selectedPage);

    axios.post(`${baseURL}/api/hero-slides`, formData)
      .then(response => {
        setNotification("Слайд успішно створений!");
        setImage(null);
        setSelectedPage("");
        setSlides(prevSlides => [...prevSlides, response.data]);
      })
      .catch(error => {
        console.error("Error creating slide:", error);
        setNotification("Помилка при створенні слайду.");
      });
  };

  const handleDeleteSlide = (id) => {
    axios.delete(`${baseURL}/api/hero-slides/${id}`)
      .then(() => {
        setSlides(prevSlides => prevSlides.filter(slide => slide._id !== id));
        setNotification("Слайд успішно видалено.");
      })
      .catch(error => {
        console.error("Error deleting slide:", error);
        setNotification("Помилка при видаленні слайду.");
      });
  };

  return (
    <div className="create_slider-hero__form">
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
      <form onSubmit={handleCreateSlide}>
        <p>Створення слайду</p>
        <div>
          <label htmlFor="image">Завантажити зображення:</label>
          <input
            type="file"
            id="image"
            onChange={handleImageChange}
            required
          />
        </div>
        <div>
          <label htmlFor="page">Сторінка підкатегорії:</label>
          <select
            id="page"
            value={selectedPage}
            onChange={handlePageChange}
            required
          >
            <option value="" disabled>Виберіть сторінку</option>
            {podCategories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Створити слайд</button>
      </form>
      <div className="slides_list">
        <h3>Список слайдів</h3>
        {slides.length > 0 ? (
          slides.map(slide => (
            <div key={slide._id} className="slide_item">
              <img src={slide.url} alt={`Slide ${slide._id}`} style={{ width: '200px', height: 'auto' }} />
              <button className="delete_btn-prev " onClick={() => handleDeleteSlide(slide._id)}><img src={DeleteIcon}/></button>
            </div>
          ))
        ) : (
          <p>Слайдів немає.</p>
        )}
      </div>
    </div>
  );
};

export default CreateSlideForm;