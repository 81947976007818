import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../CartContext';
import CloseIcon from '../../Icon/close-icon.svg';
import DeleteIcon from '../../Icon/trash.svg';
import MinusIcon from '../../Icon/minus.svg';
import PlusIcon from '../../Icon/plus.svg';

const CartModal = ({ onClose }) => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('uk-UA').format(price);
  };

  const handleOrderClick = () => {
    navigate('/order');
    onClose();
  };

  return (
    <div className="cart-modal-overlay">
      <div className="cart-modal">
        <div className="cart-modal-header">
          <h2>Кошик</h2>
          <button onClick={onClose}><img src={CloseIcon} alt="X" /></button>
        </div>
        <div className="cart-modal-body">
          {cartItems.length === 0 ? (
            <p>Кошик порожній</p>
          ) : (
            cartItems.map((item) => (
              <div key={item.id} className="cart_product-item">
                <div className="top_cart-product">
                  <img className='cart_product-img' src={item.image} alt={item.name} />
                  <p className='cart_producn-title'>{item.name}</p>
                  <button className='delete_btn-product' onClick={() => removeFromCart(item.id)}><img src={DeleteIcon} alt="X" /></button>
                </div>
                <div className="bottom_cart-product">
                  <div className="cart_product-count">
                    <button className='count_minus' onClick={() => updateQuantity(item.id, -1)}><img src={MinusIcon} alt="X" /></button>
                    <span>{item.quantity}</span>
                    <button className='count_plus' onClick={() => updateQuantity(item.id, 1)}><img src={PlusIcon} alt="X" /></button>
                  </div>
                  <p className='cart_product-price'>{formatPrice(item.price * item.quantity)} грн.</p>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="cart-modal-footer">
          <p className='full_count'>Загальна кількість: {cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
          <p className='full_price'>Загальна ціна: {formatPrice(getTotalPrice())} грн.</p>
          <button onClick={handleOrderClick}>Оформити замовлення</button>
        </div>
      </div>
    </div>
  );
};

export default CartModal;