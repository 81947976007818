import React from 'react';

import '../css-page/css-page.css'

import Nav from '../components/js-components/Nav'
import Footer from '../components/js-components/Footer'

const ProductReturn = () => {
  return (
    <div className='content_container'>
        <Nav />
        <h1 className='return_order-title'>Повернення товарів</h1>
        <p className='return_order-text'>Повернення і обміни можна поділити на дві групи:<br /><br /><br />
            <span>Перший варіант. Коли трапилася будь-яка помилка з нашого боку.</span><br /><br />
            Ми твердо переконані, що ваше замовлення щоразу має бути ідеальним. Ми щосили намагаємося мінімізувати кількість помилок, постійно покращуємо операційні і технологічні процеси, але, на жаль, не застраховані від помилок, які залежать від людського фактору.
            У разі, якщо ми помилилися - привезли вам неякісний товар або товар із поганим терміном, не додали щось у замовлення або ж помилилися інакше - ми за власний рахунок виправимо нашу помилку (обміняємо товар або повернемо гроші) якнайшвидше. Повідомити про помилку можна написавши на пошту або зателефонувавши у контакт-центр.
            Від наших клієнтів ми просимо тільки одного: якщо ми припустилися помилки з вашим замовленням, будь ласка, повідомте нам про це.
            Нам дуже важливо знати про всі помилки, яких ми припускаємось, для того щоб максимально швидко виправляти їх і робити все можливе для того, щоб не припускатись їх у майбутньому.<br /><br /><br />
            <span>Другий варіант. Коли помилки з нашого боку не було, а вам просто не підходить доставлений товар.</span><br /><br />
            Тут теж все досить просто. Якщо з моменту купівлі до вашого звернення пройшло не більше 2-х місяців, товар не був у користуванні і не втратив товарний вигляд (збереглася оригінальна упаковка, немає пошкоджень і слідів користування), просто зателефонуйте нам. Ми заберемо невідповідний товар і повернемо вам кошти.
            У разі, якщо ж товар був у користуванні, кожен випадок розглядається індивідуально. У таких випадках ми не можемо пообіцяти, що обов'язково зробимо повернення, але обов'язково намагатимемося знайти рішення, яке влаштує всіх.
        </p>
        <Footer />
    </div>
  );
};

export default ProductReturn;