import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const DeleteCheckboxes = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [filterGroupsBySubcategory, setFilterGroupsBySubcategory] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [checkboxes, setCheckboxes] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/podcategory`);
        setSubcategories(response.data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };
    fetchSubcategories();
  }, []);

  useEffect(() => {
    const fetchFilterGroups = async () => {
      if (selectedSubcategory) {
        try {
          const response = await axios.get(`${baseURL}/api/filters/${selectedSubcategory}`);
          setFilterGroupsBySubcategory(response.data);
        } catch (error) {
          console.error('Error fetching filter groups:', error);
        }
      } else {
        setFilterGroupsBySubcategory([]);
      }
    };
    fetchFilterGroups();
  }, [selectedSubcategory]);

  useEffect(() => {
    const fetchCheckboxes = async () => {
      if (selectedGroup) {
        try {
          const response = await axios.get(`${baseURL}/api/checkboxes/${selectedGroup}`);
          setCheckboxes(response.data.checkboxes || []);
        } catch (error) {
          console.error('Error fetching checkboxes:', error);
          setCheckboxes([]);
        }
      } else {
        setCheckboxes([]);
      }
    };
    fetchCheckboxes();
  }, [selectedGroup]);

  const handleDeleteCheckbox = async () => {
    if (!selectedGroup || !selectedCheckbox) {
      setNotification('Оберіть чекбокс перед видаленням');
      return;
    }
  
    try {
      await axios.delete(`${baseURL}/api/filters/${selectedGroup}/${selectedCheckbox}`);
      setCheckboxes(prev => prev.filter(c => c !== selectedCheckbox));
      setNotification('Чекбокс успішно видалено');
      setSelectedCheckbox('');
    } catch (error) {
      console.error('Error deleting checkbox:', error);
      setNotification('Помилка при видаленні чекбокса');
    }
  };

  return (
    <div className='delete_check-col'>
      <h3>Видалити чекбокси</h3>
      <select value={selectedSubcategory} onChange={(e) => setSelectedSubcategory(e.target.value)}>
        <option value="">Оберіть підкатегорію</option>
        {subcategories.map(subcategory => (
          <option key={subcategory._id} value={subcategory._id}>{subcategory.name}</option>
        ))}
      </select>

      <select value={selectedGroup} onChange={(e) => setSelectedGroup(e.target.value)} disabled={!selectedSubcategory}>
        <option value="">Оберіть групу фільтрів</option>
        {filterGroupsBySubcategory.map(group => (
          <option key={group._id} value={group._id}>{group.groupName}</option>
        ))}
      </select>

      <select value={selectedCheckbox} onChange={(e) => setSelectedCheckbox(e.target.value)} disabled={!selectedGroup}>
        <option value="">Оберіть чекбокс</option>
        {checkboxes.map((checkbox, index) => (
          <option key={index} value={checkbox}>{checkbox}</option>
        ))}
      </select>

      <button onClick={handleDeleteCheckbox} disabled={!selectedCheckbox}>Видалити</button>

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </div>
  );
};

export default DeleteCheckboxes;