import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const BlogPageForm = () => {
  const [title, setTitle] = useState('');
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState('');
  const [selectedPage, setSelectedPage] = useState('');
  const [existingPages, setExistingPages] = useState([]);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/blogpages`)
      .then(response => setExistingPages(response.data))
      .catch(error => console.error('Error fetching blog pages:', error));
  }, []);

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }

    try {
      if (selectedPage) {
        await axios.put(`${baseURL}/api/blogpages/${selectedPage}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setNotification('Сторінка успішно оновлена');
      } else {
        await axios.post(`${baseURL}/api/blogpages`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setNotification('Сторінка успішно створена');
      }

      setTitle('');
      setDescription('');
      setImages([]);
      setSelectedPage('');
      const updatedPages = (await axios.get(`${baseURL}/api/blogpages`)).data;
      setExistingPages(updatedPages);
    } catch (error) {
      console.error('Error saving blog page:', error);
      setNotification('Сталася помилка під час збереження сторінки');
    }

    setTimeout(() => setNotification(''), 3000);
  };

  const handleDelete = async () => {
    if (!selectedPage) return;

    try {
      await axios.delete(`${baseURL}/api/blogpages/${selectedPage}`);
      setNotification('Сторінка успішно видалена');
      setTitle('');
      setDescription('');
      setImages([]);
      setSelectedPage('');
      const updatedPages = (await axios.get(`${baseURL}/api/blogpages`)).data;
      setExistingPages(updatedPages);
    } catch (error) {
      console.error('Error deleting blog page:', error);
      setNotification('Сталася помилка під час видалення сторінки');
    }

    setTimeout(() => setNotification(''), 3000);
  };

  const handlePageSelection = async (e) => {
    const pageId = e.target.value;
    setSelectedPage(pageId);

    if (pageId) {
      const response = await axios.get(`${baseURL}/api/blogpages/${pageId}`);
      const { title, description, images } = response.data;
      setTitle(title);
      setDescription(description);
      setImages(images.map(image => image.url));
    } else {
      setTitle('');
      setDescription('');
      setImages([]);
    }
  };

  return (
    <div className='container_create-blog'>
      <h2>{selectedPage ? 'Редагувати сторінку блогу' : 'Створити сторінку блогу'}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Заголовок сторінки"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <textarea
          placeholder="Опис сторінки"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <input
          type="file"
          multiple
          onChange={handleImageChange}
        />
        <div className="blog_page-content">
          {images.map((image, index) => (
            <img 
              key={index} 
              src={image instanceof File ? URL.createObjectURL(image) : image} 
              alt="" 
            />
          ))}
        </div>
        <button type="submit">
          {selectedPage ? 'Оновити сторінку' : 'Створити сторінку'}
        </button>
        {selectedPage && (
          <button className='delete_blog-page' onClick={handleDelete} style={{ marginTop: '30px' }}>
            Видалити сторінку
          </button>
        )}
      </form>

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}

      <h3>Редагувати існуючу сторінку</h3>
      <select onChange={handlePageSelection} value={selectedPage}>
        <option value="">Виберіть сторінку для редагування</option>
        {existingPages.map(page => (
          <option key={page._id} value={page._id}>{page.title}</option>
        ))}
      </select>
    </div>
  );
};

export default BlogPageForm;
