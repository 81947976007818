import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Nav from '../components/js-components/Nav';
import Footer from '../components/js-components/Footer';
import UserTabs from '../page/cabinet-tab/UserTabs';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const Cabinet = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${baseURL}/api/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data);
        } else {
          console.error('Error fetching user data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  if (!user) {
    return <div className='load_page'>Loading...</div>;
  }

  return (
    <div className='content_container'>
      <Nav />
      <div className="inner_cabinet">
        <div className="top_cabinet">
          <h2>Привіт, {user.firstName} {user.lastName}!</h2>
          <button onClick={handleLogout}>Вийти</button>
        </div>
        <UserTabs />
      </div>
      <Footer />
    </div>
  );
};

export default Cabinet;