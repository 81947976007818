import React, { useState } from 'react';

import CreateProductPage from '../create-page/CreateProductPage'
import ProductEditForm from '../create-page/ProductEditForm'
import CreateCategoryCard from '../create-catalog/CreateCategoryCard'
import EditCategoryCard from '../create-catalog/EditCategoryCard'
import CreatePodCategoryPage from '../../PodCategory/CreatePodCategoryPage'
import TabPodCard from '../../PodCategory/TabPodCard'
import TabComponent from '../../page/create-filter/TabComponent'
import CreateHeroSlider from '../create-hero-slider/CreateHeroSlider'
import SliderCardManager from '../create-top-slider/CreateTop'
import SliderIntCardManager from '../create-int-slider/CreateInt'
import SliderRecCardManager from '../create-rec-slider/CreateRec'
import BlogPageForm from '../create-page/BlogPageForm'
import BlogCardManager from '../create-page/BlogCardManager'
import TabLineCard from '../slider-line/TabLineCard'

const TabFiller = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const renderContent = () => {
    switch (activeTab) {
      case 'tab1':
        return <div><CreateProductPage /></div>;
      case 'tab2':
        return <div><ProductEditForm /></div>;
      case 'tab3':
        return <div><CreateCategoryCard /></div>;
      case 'tab4':
        return <div><EditCategoryCard /></div>;
      case 'tab5':
        return <div><CreatePodCategoryPage /></div>;
      case 'tab7':
        return <div><TabPodCard /></div>;
      case 'tab8':
        return <div><TabComponent /></div>;
      case 'tab9':
        return <div><CreateHeroSlider /></div>;
      case 'tab10':
        return <div><SliderCardManager /></div>;
      case 'tab11':
        return <div><SliderIntCardManager /></div>;
      case 'tab12':
        return <div><SliderRecCardManager /></div>;
      case 'tab13':
        return <div><BlogPageForm /></div>;
      case 'tab14':
        return <div><BlogCardManager /></div>;
      case 'tab15':
        return <div><TabLineCard /></div>;
      default:
        return <div>Виберіть таб для перегляду контенту</div>;
    }
  };

  return (
    <div className="tab_container-adm">
      <div className="tab_buttons-adm">
        <button onClick={() => setActiveTab('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>Створення сторінки</button>
        <button onClick={() => setActiveTab('tab2')} className={activeTab === 'tab2' ? 'active' : ''}>Редагування сторінки</button>
        <button onClick={() => setActiveTab('tab3')} className={activeTab === 'tab3' ? 'active' : ''}>Створення каталогу</button>
        <button onClick={() => setActiveTab('tab4')} className={activeTab === 'tab4' ? 'active' : ''}>Редагування каталогу</button>
        <button onClick={() => setActiveTab('tab5')} className={activeTab === 'tab5' ? 'active' : ''}>Створення підкатегорій</button>
        <button onClick={() => setActiveTab('tab15')} className={activeTab === 'tab15' ? 'active' : ''}>Створення рекомендацій</button>
        <button onClick={() => setActiveTab('tab7')} className={activeTab === 'tab7' ? 'active' : ''}>Картки продуктів</button>
        <button onClick={() => setActiveTab('tab8')} className={activeTab === 'tab8' ? 'active' : ''}>Фільтри</button>
        <button onClick={() => setActiveTab('tab9')} className={activeTab === 'tab9' ? 'active' : ''}>Слайдер головної сторінки</button>
        <button onClick={() => setActiveTab('tab10')} className={activeTab === 'tab10' ? 'active' : ''}>Слайдер ТОП продажу</button>
        <button onClick={() => setActiveTab('tab11')} className={activeTab === 'tab11' ? 'active' : ''}>Слайдер Може зацікавити</button>
        <button onClick={() => setActiveTab('tab12')} className={activeTab === 'tab12' ? 'active' : ''}>Слайдер Рекомендоване</button>
        <button onClick={() => setActiveTab('tab13')} className={activeTab === 'tab13' ? 'active' : ''}>Створити сторінку блогу</button>
        <button onClick={() => setActiveTab('tab14')} className={activeTab === 'tab14' ? 'active' : ''}>Створити картку блогу</button>
      </div>
      <div className="tab_content-adm">
        {renderContent()}
      </div>
    </div>
  );
};

export default TabFiller;