import React, { useState, useEffect } from 'react';
import CheckboxManager from '../create-filter/CheckboxManager';
import AddCheckboxes from '../create-filter/AddCheckboxes';
import DeleteCheckboxes from '../create-filter/DeleteCheckboxes';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState('create');
  const [subcategories, setSubcategories] = useState([]);
  const [filterGroupsBySubcategory, setFilterGroupsBySubcategory] = useState([]);

  useEffect(() => {
    axios.get(`${baseURL}/api/podcategory`)
      .then(response => setSubcategories(response.data))
      .catch(error => console.error('Error loading subcategories:', error));
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="tabs_filter">
        <button onClick={() => handleTabChange('create')}>Створити групи фільтрів</button>
        <button onClick={() => handleTabChange('add')}>Додати чекбокси</button>
        <button onClick={() => handleTabChange('delete')}>Видалити чекбокси</button>
      </div>

      {activeTab === 'create' && (
        <CheckboxManager subcategories={subcategories} />
      )}
      {activeTab === 'add' && (
        <AddCheckboxes subcategories={subcategories} filterGroupsBySubcategory={filterGroupsBySubcategory} />
      )}
      {activeTab === 'delete' && (
        <DeleteCheckboxes subcategories={subcategories} filterGroupsBySubcategory={filterGroupsBySubcategory} />
      )}
    </div>
  );
};

export default TabComponent;