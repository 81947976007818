import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

import CloseIcon from '../../Icon/close-icon.svg';
import HideIcon from '../../Icon/hide-icon.svg';
import SendIcon from '../../Icon/send.svg';
import ChatIcon from '../../Icon/chat-icon.svg';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const ChatBtn = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [username, setUsername] = useState('');
  const [text, setText] = useState('');
  const [operatorTyping, setOperatorTyping] = useState(false);
  const [userId] = useState(uuidv4());
  const socketRef = useRef(null);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(baseURL, {
        withCredentials: true,
      });

      socketRef.current.emit('join', userId);

      socketRef.current.on('newMessage', (message) => {
        setMessages((prevMessages) => {
          const messageExists = prevMessages.find(msg => msg.id === message.id);
          if (messageExists) return prevMessages;
          return [...prevMessages, message];
        });
      });

      socketRef.current.on('operatorTyping', () => {
        setOperatorTyping(true);
      });

      socketRef.current.on('operatorStoppedTyping', () => {
        setOperatorTyping(false);
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [userId]);

  const toggleChat = () => setIsOpen(!isOpen);
  const closeChat = () => {
    setIsOpen(false);
    setMessages([]);
  };

  const sendMessage = () => {
    if (text.trim()) {
      const newMessage = { id: uuidv4(), userId, text, username, isOperator: false };
      socketRef.current.emit('sendMessage', newMessage);
      setMessages((prevMessages) => {
        const messageExists = prevMessages.find(msg => msg.id === newMessage.id);
        if (messageExists) return prevMessages;
        return [...prevMessages, newMessage];
      });
      setText('');
    }
  };

  return (
    <div className="chat-container">
      <button className="chat-button" onClick={toggleChat}>
        Допомога
        <img src={ChatIcon} alt="Chat Icon" />
      </button>
      {isOpen && (
        <div className="chat-modal">
          <div className="chat-header">
            <button className='close' onClick={closeChat}><img src={CloseIcon} alt="Close Icon" /></button>
            <button className='hide' onClick={toggleChat}><img src={HideIcon} alt="Hide Icon" /></button>
          </div>
          <div className="chat-body">
            {messages.map((msg) => (
              <div key={msg.id} className={msg.isOperator ? 'operator-message' : 'user-message'}>
                {msg.username}: {msg.text}
              </div>
            ))}
            {operatorTyping && <div className="typing-indicator">Оператор друкує...</div>}
          </div>
          <div className="chat-footer">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Ваше ім'я"
            />
            <div className="msg_btn">
              <input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                placeholder="Повідомлення"
              />
              <button onClick={sendMessage}><img src={SendIcon} alt="Send Icon" /></button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBtn;