import React, { useState, useEffect, useContext } from 'react';
import { CartContext } from '../CartContext';
import Nav from '../components/js-components/Nav';
import Footer from '../components/js-components/Footer';
import ReviewForm from '../product-page/ReviewForm';
import ChatBtn from '../page/chat/ChatBtn';
import CartModal from '../components/js-components/CartModal';
import WishIcon from '../Icon/wish-icon.svg';
import TopSlider from '../page/slider/TopSlider';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const PageTemplate = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [notification, setNotification] = useState('');
  const { addToCart, cartItems, isProductInCart, toggleCart } = useContext(CartContext);

  const isUserAuthenticated = () => {
    return !!localStorage.getItem('token');
  };

  useEffect(() => {
    axios.get(`${baseURL}/api/products/${id}`)
      .then(response => {
        setProduct(response.data);
      })
      .catch(error => {
        console.error('Error fetching product data:', error);
      });
  }, [id]);

  useEffect(() => {
    if (product) {
      const itemInCart = isProductInCart(product._id);
      setIsInCart(itemInCart);
    }
  }, [cartItems, product, isProductInCart]);

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification('');
    }, 3000);
  };

  if (!product) return <p className='loading'>Завантаження...</p>;

  const images = product.images || [];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('uk-UA').format(price);
  };

  const handleAddToCart = () => {
    addToCart({
      id: product._id,
      name: product.name,
      price: product.price,
      image: `${baseURL}/${images[currentIndex]}`,
      quantity: 1,
    });
    setIsInCart(true);
    toggleCart();
    showNotification('Продукт додано до кошика');
  };

  const addToWishlist = (product) => {
    if (!isUserAuthenticated()) {
      showNotification('Будь ласка, увійдіть до системи, щоб додати продукт до бажаного.');
      return;
    }
  
    let wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    
    const isAlreadyInWishlist = wishlist.some(item => item.id === product._id);
    if (isAlreadyInWishlist) {
      showNotification('Цей продукт вже додано до бажаного.');
      return;
    }
  
    const productUrl = `/product/${product._id}`;
  
    wishlist.push({
      id: product._id,
      name: product.name,
      image: product.images && product.images.length > 0 ? product.images[0] : '',
      url: productUrl,
    });
  
    localStorage.setItem('wishlist', JSON.stringify(wishlist));
    showNotification('Продукт успішно додано до бажаного.');
  };

  const leftCharacteristics = Array.isArray(product.characteristics?.left)
    ? product.characteristics.left
    : [];
  const rightCharacteristics = Array.isArray(product.characteristics?.right)
    ? product.characteristics.right
    : [];

  const getStockClass = (availability) => {
    switch (availability.toLowerCase()) {
      case 'в наявності':
        return 'stock in-stock';
      case 'закінчується':
        return 'stock running-out';
      case 'закінчилось':
        return 'stock out-of-stock';
      default:
        return 'stock';
    }
  };

  const stockStatus = product.availability === 'В наявності'
    ? 'В наявності'
    : product.availability === 'Закінчується'
    ? 'Закінчується'
    : 'Закінчилось';

  return (
    <div className="content_container">
      <Nav />
      <div className="grid_container">
        <div className="slider">
          <div className="main-image">
            <img src={`${baseURL}/${images[currentIndex]}`} alt="Product" />
            <button className="prev" onClick={prevSlide}>❮</button>
            <button className="next" onClick={nextSlide}>❯</button>
          </div>
          <div className="thumbnails">
            {images.map((image, index) => (
              <img
                key={index}
                src={`${baseURL}/${image}`}
                alt="Thumbnail"
                className={index === currentIndex ? 'active' : ''}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        </div>
        <div className="descr_container">
          <p className="product_title">{product.name}</p>
          <p className={getStockClass(product.availability)}>{stockStatus}</p>
          <p className="product_price">{formatPrice(product.price)} грн.</p>
          <div className="wish_order">
            {product.availability.toLowerCase() !== 'закінчилось' && (
              <button
                className="product_order-btn"
                onClick={handleAddToCart}
              >
                {isInCart ? 'В кошику' : 'Купити'}
              </button>
            )}
            <button 
              className="wish_btn"
              onClick={() => addToWishlist(product)}>
              <img src={WishIcon} alt="Wish" />
            </button>
          </div>
          <p className="char_text">Характеристики</p>
          <div className="char_container">
            {leftCharacteristics.map((charLeft, index) => (
              <div className="char_item" key={index}>
                <div className="char_col-left">
                  <span>{charLeft}</span>
                </div>
                <div className="char_col-right">
                  <span>{rightCharacteristics[index]}</span>
                </div>
              </div>
            ))}
          </div>
          <ReviewForm />
        </div>
      </div>
      <TopSlider />
      {isCartOpen && <CartModal onClose={() => setIsCartOpen(false)} />}
      <ChatBtn />
      <Footer />
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </div>
  );
};

export default PageTemplate;
