import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

import TabFiller from '../adm-tab/TabFiller';
import TabContact from '../adm-tab/TabContact';
import TabRev from '../adm-tab/TabRev';
import OperatorChat from '../chat/OperatorChat';

const socket = io('https://uptonnn.onrender.com');

const TabMain = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    const handleNewMessage = (message) => {
      if (activeTab !== 'tab5') {
        setUnreadMessages((prevCount) => prevCount + 1);
      }
    };

    socket.on('newMessageToOperator', handleNewMessage);

    return () => {
      socket.off('newMessageToOperator', handleNewMessage);
    };
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 'tab5') {
      setUnreadMessages(0);
    }
  }, [activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case 'tab1':
        return <TabFiller />;
      case 'tab3':
        return <TabContact />;
      case 'tab4':
        return <TabRev />;
      case 'tab5':
        return <OperatorChat socket={socket} />;
      default:
        return <div>Виберіть таб для перегляду контенту</div>;
    }
  };

  return (
    <div className="tab_container-main">
      <div className="tab_buttons-main">
        <button onClick={() => setActiveTab('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>Наповнення сайту</button>
        <button onClick={() => setActiveTab('tab3')} className={activeTab === 'tab3' ? 'active' : ''}>Редагування інформації</button>
        <button onClick={() => setActiveTab('tab4')} className={activeTab === 'tab4' ? 'active' : ''}>Відгуки</button>
        <button onClick={() => setActiveTab('tab5')} className={activeTab === 'tab5' ? 'active' : ''}>
          Чат підтримки {unreadMessages > 0 && <span className="badge">{unreadMessages}</span>}
        </button>
      </div>
      <div className="tab_content-main">
        {renderContent()}
      </div>
    </div>
  );
};

export default TabMain;
