import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PlusIcon from '../../Icon/plus-input.svg';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CheckboxManager = () => {
  const [filterGroups, setFilterGroups] = useState([{ groupName: '', checkboxes: [''] }]);
  const [subcategories, setSubcategories] = useState([]);
  const [filterGroupsBySubcategory, setFilterGroupsBySubcategory] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/podcategory`)
      .then(response => setSubcategories(response.data))
      .catch(error => console.error('Error loading subcategories:', error));
  }, []);

  useEffect(() => {
    if (selectedSubcategory) {
      axios.get(`${baseURL}/api/filters/${selectedSubcategory}`)
        .then(response => setFilterGroupsBySubcategory(response.data))
        .catch(error => console.error('Error fetching filter groups:', error));
    }
  }, [selectedSubcategory]);

  const handleGroupNameChange = (index, value) => {
    const newFilterGroups = [...filterGroups];
    newFilterGroups[index].groupName = value;
    setFilterGroups(newFilterGroups);
  };

  const handleCheckboxChange = (groupIndex, checkboxIndex, value) => {
    const newFilterGroups = [...filterGroups];
    newFilterGroups[groupIndex].checkboxes[checkboxIndex] = value;
    setFilterGroups(newFilterGroups);
  };

  const addCheckbox = (groupIndex) => {
    const newFilterGroups = [...filterGroups];
    newFilterGroups[groupIndex].checkboxes.push('');
    setFilterGroups(newFilterGroups);
  };

  const addFilterGroup = () => {
    setFilterGroups([...filterGroups, { groupName: '', checkboxes: [''] }]);
  };

  const handleSubmit = async () => {
    if (!selectedSubcategory) {
      setNotification('Оберіть підкатегорію перед збереженням');
      return;
    }

    try {
      await axios.post(`${baseURL}/api/filters`, { subcategory: selectedSubcategory, filters: filterGroups });
      setFilterGroups([{ groupName: '', checkboxes: [''] }]);
      setNotification('Фільтри успішно збережені');
    } catch (error) {
      console.error('Error saving filters:', error);
      setNotification('Помилка при збереженні фільтрів');
    }
  };

  return (
    <div className='create_filter-container'>
      <h3>Створити фільтри</h3>
      <select value={selectedSubcategory} onChange={(e) => setSelectedSubcategory(e.target.value)}>
        <option value="">Оберіть підкатегорію</option>
        {subcategories.map(subcategory => (
          <option key={subcategory._id} value={subcategory._id}>{subcategory.name}</option>
        ))}
      </select>
      <p>Назва групи фільтрів</p>
      {filterGroups.map((group, groupIndex) => (
        <div key={groupIndex} className='create_filter-item'>
          <input
            type="text"
            value={group.groupName}
            onChange={(e) => handleGroupNameChange(groupIndex, e.target.value)}
            placeholder="Наприклад 'Бренд' або 'Особливі потреби'"
          />
          <div className="check_item-col">
            <div className="check_item-col__input">
              {group.checkboxes.map((checkbox, checkboxIndex) => (
                <input
                  key={checkboxIndex}
                  type="text"
                  value={checkbox}
                  onChange={(e) => handleCheckboxChange(groupIndex, checkboxIndex, e.target.value)}
                  placeholder={`Наприклад "Optimeal" ${checkboxIndex + 1}`}
                />
              ))}
            </div>
            <button className='plus_icon-btn' onClick={() => addCheckbox(groupIndex)}>
              <img src={PlusIcon} alt="Add Checkbox"/>
            </button>
          </div>
        </div>
      ))}
      <button onClick={addFilterGroup}>Додати ще одну групу</button>
      <button onClick={handleSubmit}>Зберегти</button>

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </div>
  );
};

export default CheckboxManager;