import React, { useState, useEffect } from 'react';

import DeleteBtn from '../../Icon/trash.svg'

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filter, setFilter] = useState('all');
  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    const storedOrders = JSON.parse(localStorage.getItem('orders')) || [];

    const parsedOrders = storedOrders.map(order => ({
      ...order,
      date: parseDateString(order.date),
      id: generateUniqueId()
    }));

    setOrders(parsedOrders);
    setFilteredOrders(parsedOrders);
  }, []);

  const parseDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('.');
    return new Date(`${year}-${month}-${day}T${timePart}`);
  };

  const generateUniqueId = () => `id-${Math.random().toString(36).substr(2, 9)}`;

  const filterOrders = (period) => {
    const now = new Date();
    let filtered = orders;

    switch (period) {
      case 'thisMonth':
        filtered = orders.filter(order => {
          const orderDate = new Date(order.date);
          return (
            orderDate.getMonth() === now.getMonth() &&
            orderDate.getFullYear() === now.getFullYear()
          );
        });
        break;
      case 'lastMonth':
        filtered = orders.filter(order => {
          const orderDate = new Date(order.date);
          const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1);
          return (
            orderDate.getMonth() === lastMonth.getMonth() &&
            orderDate.getFullYear() === lastMonth.getFullYear()
          );
        });
        break;
      case 'thisYear':
        filtered = orders.filter(order => {
          const orderDate = new Date(order.date);
          return orderDate.getFullYear() === now.getFullYear();
        });
        break;
      case 'lastYear':
        filtered = orders.filter(order => {
          const orderDate = new Date(order.date);
          return orderDate.getFullYear() === now.getFullYear() - 1;
        });
        break;
      default:
        filtered = orders;
    }

    setFilteredOrders(filtered);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    filterOrders(event.target.value);
  };

  const handleSelectOrder = (orderId) => {
    setSelectedOrders(prevSelectedOrders =>
      prevSelectedOrders.includes(orderId)
        ? prevSelectedOrders.filter(id => id !== orderId)
        : [...prevSelectedOrders, orderId]
    );
  };

  const handleSelectAllOrders = () => {
    if (selectedOrders.length === filteredOrders.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(filteredOrders.map(order => order.id));
    }
  };

  const isOrderSelected = (orderId) => selectedOrders.includes(orderId);

  const handleDeleteSelectedOrders = () => {
    const updatedOrders = orders.filter(order => !selectedOrders.includes(order.id));
    setOrders(updatedOrders);
    setFilteredOrders(updatedOrders);
    setSelectedOrders([]);
    localStorage.setItem('orders', JSON.stringify(updatedOrders));
  };

  return (
    <div className='order__content-conteinet_tab'>
      <h1>Ваші замовлення</h1>
      <div className="select_btn-item">
        <label className='filter-label'>
          <p>Фільтр:</p>
          <select value={filter} onChange={handleFilterChange}>
            <option value="all">Всі замовлення</option>
            <option value="thisMonth">Цього місяця</option>
            <option value="lastMonth">Минулий місяць</option>
            <option value="thisYear">Цього року</option>
            <option value="lastYear">Минулий рік</option>
          </select>
        </label>
        <div className="button_check-item">
          {filteredOrders.length > 0 && (
            <button onClick={handleSelectAllOrders}>
              {selectedOrders.length === filteredOrders.length ? "Відмінити вибір" : "Обрати всі"}
            </button>
          )}
          {selectedOrders.length > 0 && (
            <button onClick={handleDeleteSelectedOrders} style={{ display: selectedOrders.length > 0 ? 'block' : 'none' }}>
              <img src={DeleteBtn} alt="Delete" />
            </button>
          )}
        </div>
      </div>
      <ul className='ul_item-grid'>
        {filteredOrders.map((order, index) => (
          <li className='order_card-tab' key={index}>
            <input className='check_card-input'
              type="checkbox"
              checked={isOrderSelected(order.id)}
              onChange={() => handleSelectOrder(order.id)}
            />
            <p><b>Дата:</b> {order.date.toLocaleString()}</p>
            <p><b>Замовлення:</b></p>
            <ul>
              {order.cartItems.map((item, idx) => (
                <li className='order_card-teb__inner' key={idx}>
                  <img src={item.image} alt={item.name} />
                  <p>{item.name} - {item.quantity} шт. - {item.price} грн.</p>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Orders;
