import React, { useState } from 'react';

import CreateCard from '../PodCategory/CreateCard'
import EditCard from '../PodCategory/EditCard'
import DeleteCard from '../PodCategory/DeleteCard'

const TabPodCard = () => {
  const [selectedTab, setSelectedTab] = useState('create');

  return (
    <div className="slider-card-manager">
      <div className="tabs_line">
        <button className='tab_btn_slider' onClick={() => setSelectedTab('create')}>Створити картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('edit')}>Редагувати картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('delete')}>Видалити картку</button>
      </div>
      
      <div className="content_slider-create">
        {selectedTab === 'create' && <CreateCard />}
        {selectedTab === 'edit' && <EditCard />}
        {selectedTab === 'delete' && <DeleteCard />}
      </div>
    </div>
  );
};

export default TabPodCard;