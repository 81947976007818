import React, { useState } from 'react';
import RecCardCreate from './RecCardCreate';
import RecCardEdit from './RecCardEdit';
import RecCardDelete from './RecCardDelete';

const SliderCardManager = () => {
  const [selectedTab, setSelectedTab] = useState('create');

  return (
    <div className="slider-card-manager">
      <div className="tabs_line">
        <button className='tab_btn_slider' onClick={() => setSelectedTab('create')}>Створити картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('edit')}>Редагувати картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('delete')}>Видалити картку</button>
      </div>
      
      <div className="content_slider-create">
        {selectedTab === 'create' && <RecCardCreate />}
        {selectedTab === 'edit' && <RecCardEdit />}
        {selectedTab === 'delete' && <RecCardDelete />}
      </div>
    </div>
  );
};

export default SliderCardManager;