import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '../src/reset.css'
import '../src/index.css'
import { BrowserRouter } from 'react-router-dom';
import { CartProvider } from './CartContext';
import { ChatProvider } from './ChatContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <CartProvider>
      <ChatProvider>
        <App />
      </ChatProvider>
    </CartProvider>
  </BrowserRouter>
);