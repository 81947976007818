import React, { useContext } from 'react';
import { CartContext } from '../../CartContext';
import BagIcon from '../../Icon/bag-icon.svg';
import '../css-components/css-components.css';

const Bag = () => {
  const { cartItems, toggleCart } = useContext(CartContext);

  return (
    <>
      <button className='bag__btn' onClick={toggleCart}>
        <img src={BagIcon} alt="Bag Icon" />
        {cartItems.length > 0 && <span className='bag__indicator'></span>}
      </button>
    </>
  );
};

export default Bag;