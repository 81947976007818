import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const MainInfoTab = () => {
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    city: '',
    deliveryMethod: '',
    deliveryAddress: '',
    deliveryIndex: '',
    paymentMethod: ''
  });

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${baseURL}/api/user`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserInfo(response.data);
      } catch (error) {
        console.error('Failed to fetch user data', error);
      }
    };

    fetchUserInfo();
  }, []);

  const handleInputChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value
    });
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
  
    if (newPassword !== confirmNewPassword) {
      alert('Новий пароль і підтвердження пароля не співпадають');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${baseURL}/api/user/change-password`, 
      {
        currentPassword, 
        newPassword 
      }, 
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setNotification('Пароль успішно оновлено');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      console.error('Failed to update password', error);
      setNotification('Не вдалося оновити пароль');
    }

    setTimeout(() => setNotification(''), 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${baseURL}/api/user/update`, userInfo, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setNotification('Дані оновлені');
    } catch (error) {
      console.error('Failed to update user data', error);
      setNotification('Не вдалося оновити дані');
    }

    setTimeout(() => setNotification(''), 3000);
  };

  return (
    <form className='cabinet_form-container' onSubmit={handleSubmit}>
      <div className='form_group'>
        <h3>Особиста інформація</h3>
        <label>
          <p>Ім'я:</p>
          <input
            type='text'
            name='firstName'
            value={userInfo.firstName}
            onChange={handleInputChange}
          />
        </label>
        <label>
          <p>Прізвище:</p>
          <input
            type='text'
            name='lastName'
            value={userInfo.lastName}
            onChange={handleInputChange}
          />
        </label>
        <label>
          <p>Телефон:</p>
          <input
            type='text'
            name='phone'
            value={userInfo.phone}
            onChange={handleInputChange}
          />
        </label>
        <label>
          <p>Електронна пошта:</p>
          <input
            type='email'
            name='email'
            value={userInfo.email}
            onChange={handleInputChange}
          />
        </label>
      </div>

      <div className='form_group'>
        <h3>Доставка та оплата</h3>
        <label>
          <p>Місто:</p>
          <input
            type='text'
            name='city'
            value={userInfo.city}
            onChange={handleInputChange}
          />
        </label>
        <label>
          <p>Спосіб доставки:</p>
          <select
            name='deliveryMethod'
            value={userInfo.deliveryMethod}
            onChange={handleInputChange}
          >
            <option value=''>Оберіть спосіб доставки</option>
            <option value='Нова пошта відділення'>Нова пошта відділення</option>
            <option value='Нова пошта поштомат'>Нова пошта поштомат</option>
            <option value='Нова пошта курєром'>Нова пошта кур'єром</option>
            <option value='Укрпошта'>Укрпошта</option>
          </select>
        </label>
        <label>
          <p>Адреса відділення:</p>
          <input
            type='text'
            name='deliveryAddress'
            value={userInfo.deliveryAddress}
            onChange={handleInputChange}
          />
        </label>
        <label>
          <p>Номер/індекс відділення:</p>
          <input
            type='text'
            name='deliveryIndex'
            value={userInfo.deliveryIndex}
            onChange={handleInputChange}
          />
        </label>
        <label>
          <p>Спосіб оплати:</p>
          <select
            name='paymentMethod'
            value={userInfo.paymentMethod}
            onChange={handleInputChange}
          >
            <option value=''>Оберіть спосіб оплати</option>
            <option value='Оплата при отриманні'>Оплата при отриманні</option>
            <option value='Банківський переказ'>Банківський переказ</option>
            <option value='Оплата за реквізитами'>Оплата за реквізитами</option>
          </select>
        </label>
      </div>

      <button className='save__btn' type='submit'>Зберегти зміни</button>

      <div className='form_group'>
        <h3>Зміна пароля</h3>
        <label>
          <p>Поточний пароль:</p>
          <input
            type='password'
            name='currentPassword'
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </label>
        <label>
          <p>Новий пароль:</p>
          <input
            type='password'
            name='newPassword'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </label>
        <label>
          <p>Підтвердити новий пароль:</p>
          <input
            type='password'
            name='confirmNewPassword'
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </label>
        <button className='save__btn-pass' onClick={handlePasswordChange}>Оновити пароль</button>
      </div>

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </form>
  );
};

export default MainInfoTab;