import React from 'react';

import '../css-page/css-page.css'

import Nav from '../components/js-components/Nav'
import Footer from '../components/js-components/Footer'

const PrivacyPolicy = () => {
  return (
    <div className='content_container'>
        <Nav />
        <h1 className='return_order-title'>Політика конфіденційності</h1>
        <p className='return_order-text'>
            Цей розділ описує процес збору, зберігання, використання, поширення та захисту Вашої особистої інформації під час відвідування вебсайту та оформлення замовлень на сайті Відвідуючи вебсайт і / або оформляючи замовлення, Ви погоджуєтесь із цією<br /><br />

            <span>Політикою конфіденційності.</span><br /><br /><br />
            Наш сайт дуже серйозно ставиться до збереження особистої інформації користувачів і вживає всіх необхідних заходів для забезпечення її безпеки. Для цього застосовуються різноманітні технології та процедури, що забезпечують захист від несанкціонованого доступу, використання або розкриття інформації.<br /><br />

            <span>Персональна інформація</span><br /><br /><br />
            Збір і використання у процесі взаємодії з Клієнтом наш магазин збирає необхідну інформацію про Клієнтів із метою забезпечення найвищого рівня сервісу та для зв’язку з Клієнтом. Персональна інформація може бути змінена або доповнена за запитом самого Клієнта.
            Ця інформація не продається та не передається третім особам за жодних обставин (виняток може бути зроблено тільки за рішенням суду, третейського суду).<br /><br />

            <span>Видалення профілю</span><br /><br /><br />
            Для того, щоб видалити свій профіль, будь ласка, напишіть повідомлення в зручний месенджер або зателефонуйте по одному з номерів в розділі "Контакти" (який знаходиться в самому кінці сторінки). Ми опрацюємо Ваш запит протягом трьох робочих днів і видалимо Ваш профіль.<br /><br />

            <span>Інформування Клієнтів</span><br /><br /><br />
            Наш магазин за допомогою телефону, електронної пошти, смс-інформування та інших каналів зв’язку може поширювати серед своїх Клієнтів інформацію про існуючі продукти та послуги, акції, спеціальні пропозиції, конкурси та інші заходи. Проте кожне подібне повідомлення містить опцію, за допомогою якої користувач має можливість припинити / призупинити отримання цих повідомлень.<br />
            Адресні бази клієнтів за жодних умов не продаються та не передаються третім особам.<br /><br />

            <span>Зміни</span><br /><br /><br />
            Ця Політика конфіденційності не є остаточною, може бути змінена та доповнена. Про всі зміни в Політиці конфіденційності клієнти будуть повідомлені про це електронною поштою або спеціальним повідомленням на головній сторінці сайту.<br /><br /><br />
            <span>Контакти</span><br /><br />
            З усіх питань, які стосуються дотримання конфіденційності наших користувачів, будь ласка, звертайтеся до наших менеджерів.
        </p>
        <Footer />
    </div>
  );
};

export default PrivacyPolicy;