import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const NewReviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    axios.get(`${baseURL}/api/reviews`)
      .then(response => {
        setReviews(response.data);
      })
      .catch(error => {
        console.error('Помилка при завантаженні відгуків:', error);
      });
  }, []);

  return (
    <div className="reviews_container">
      {reviews.map((review, index) => (
        <div key={index} className="review_card-rev">
          <Link to={`/product/${review.productId._id}`} className="product_link">
            <p className="product_title-rev">{review.productId.name}</p>
          </Link>
          <div className='rev__date-user'>
            <p className="review_user-rev">{review.name}</p>
            <p className="review_date-rev">Дата: {review.date}</p>
          </div>
          <p className="review_text-rev">{review.text}</p>
        </div>
      ))}
    </div>
  );
};

export default NewReviews;