import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import SocIcon1 from '../../Icon/footer-icon/f-f.svg';
import SocIcon2 from '../../Icon/footer-icon/i-f.svg';
import SocIcon3 from '../../Icon/footer-icon/tl-f.svg';
import SocIcon4 from '../../Icon/footer-icon/t-f.svg';
import SocIcon5 from '../../Icon/footer-icon/v-f.svg';
import SocIcon6 from '../../Icon/footer-icon/tel-f.svg';

import LogoIcon from '../../Icon/logo_icon.svg';

import '../css-components/css-components.css';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const Footer = () => {
  const [links, setLinks] = useState({
    facebook: '',
    instagram: '',
    telegram: '',
    tiktok: '',
    viber: '',
    phone1: '',
    phone2: ''
  });

  useEffect(() => {
    axios.get(`${baseURL}/api/footerLinks`)
      .then(response => {
        if (response.data) {
          setLinks(response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching footer links:', error);
      });
  }, []);

  return (
    <div className="footer_container">
      <div className="footer__col">
        <Link className='logo__link' to="/"><img src={LogoIcon} alt="logo" /></Link>
      </div>
      <div className="footer__col">
        <p className='col__title'>Інформація</p>
        <Link className='col__descr' to="/povernennya-produktu">Повернення товарів</Link>
        <Link className='col__descr' to="/polityka-konfidentsiynosti">Політика конфіденційності</Link>
        <Link className='col__descr' to="/umovy-vykorystannya">Умови використання</Link>
      </div>
      <div className="footer__col">
        <p className='col__title'>Контакти</p>
        {links.facebook && (
          <Link className='soc__link' to={links.facebook} target="blank">
            <img src={SocIcon1} alt="Facebook" />Facebook
          </Link>
        )}
        {links.instagram && (
          <Link className='soc__link' to={links.instagram} target="blank">
            <img src={SocIcon2} alt="Instagram" />Instagram
          </Link>
        )}
        {links.telegram && (
          <Link className='soc__link' to={links.telegram} target="blank">
            <img src={SocIcon3} alt="Telegram" />Telegram
          </Link>
        )}
        {links.tiktok && (
          <Link className='soc__link' to={links.tiktok} target="blank">
            <img src={SocIcon4} alt="TikTok" />TikTok
          </Link>
        )}
        <div className="footer_item-tel">
          {links.phone1 && (
            <Link className='tel__link-footer' to={`tel:${links.phone1}`}>
              <img src={SocIcon6} alt="Viber" />
              {links.phone1}
            </Link>
          )}
          {links.phone2 && (
            <Link className='tel__link-footer' to={`tel:${links.phone2}`}>
              <img src={SocIcon6} alt="Viber" />
              {links.phone2}
            </Link>
          )}
        </div>
      </div>
      <div className="footer__col">
        <p className='col__title'>Оплата</p>
        <p className='col__descr'>Оплата при отриманні</p>
        <p className='col__descr'>Безготівковий розрахунок</p>
        <p className='col__descr'>Банківський переказ</p>
        <p className='col__descr-last'>На розрахунковий рахунок</p>
      </div>
    </div>
  );
};

export default Footer;
