import React, { useState } from 'react';
import SliderCardCreate from './SliderCardCreate';
import SliderCardEdit from './SliderCardEdit';
import SliderCardDelete from './SliderCardDelete';

const SliderCardManager = () => {
  const [selectedTab, setSelectedTab] = useState('create');

  return (
    <div className="slider-card-manager">
      <div className="tabs_line">
        <button className='tab_btn_slider' onClick={() => setSelectedTab('create')}>Створити картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('edit')}>Редагувати картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('delete')}>Видалити картку</button>
      </div>
      
      <div className="content_slider-create">
        {selectedTab === 'create' && <SliderCardCreate />}
        {selectedTab === 'edit' && <SliderCardEdit />}
        {selectedTab === 'delete' && <SliderCardDelete />}
      </div>
    </div>
  );
};

export default SliderCardManager;