import React, { useState } from 'react';
import CreateCardForm from './CreateCardForm';
import EditCardForm from './EditCardForm';
import DeleteCard from './DeleteCard';

const TabLineCard = () => {
  const [selectedTab, setSelectedTab] = useState('create');

  return (
    <div className="slider-card-manager">
      <div className="tabs_line">
        <button className='tab_btn_slider' onClick={() => setSelectedTab('create')}>Створити картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('edit')}>Редагувати картку</button>
        <button className='tab_btn_slider' onClick={() => setSelectedTab('delete')}>Видалити картку</button>
      </div>
      
      <div className="content_slider-create">
        {selectedTab === 'create' && <CreateCardForm />}
        {selectedTab === 'edit' && <EditCardForm />}
        {selectedTab === 'delete' && <DeleteCard />}
      </div>
    </div>
  );
};

export default TabLineCard;
