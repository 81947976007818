import React from 'react';

import '../css-page/css-page.css'

import Nav from '../components/js-components/Nav'
import Footer from '../components/js-components/Footer'

const TermsOfUse = () => {
  return (
    <div className='content_container'>
        <Nav />
        <h1 className='return_order-title'>Умови використання</h1>
        <p className='return_order-text'>
            <span>Шановні клієнти та відвідувачі сайту, Цей розділ описує умови користування сайтом. Будь ласка, уважно прочитайте ці умови перед тим, як користуватися сайтом. Якщо ви не згодні з одним або декількома пунктами пропонованих умов, не використовуйте цей вебсайт. Магазин залишає за собою право в будь-який час вносити зміни та доповнення в ці умови без спеціального повідомлення користувачів.</span><br /><br /><br /><br />
            <span>Інформація</span><br /><br />
            Уся інформація на цьому сайті належить власнику сайту. Збір інформації, її перегляд, копіювання, публікування та використання дозволяється виключно для особистого некомерційного користування за умови обов’язкового розміщення гіперпосилання на наш сайт!<br /><br />
            <span>Авторські права на логотипи, тексти та зображення належать їхнім власникам.</span><br /><br />
            Попри те, що наш магазин використовує всі можливі сили для розміщення вірної та актуальної інформації, ми не можемо повністю гарантувати достовірність і точність матеріалів і даних, розміщених на сайті. Матеріали та дані на вебсайті, включно з будь-якою інформацією, можуть мати неточність і помилки.<br /><br />
            <span>Компанія залишає за собою право</span> редагувати та оновлювати матеріали та інформацію, розміщені на сайті, без попереднього повідомлення.
            Наш магазин робить усе можливе, щоби цей вебсайт був доступний 24 години на добу, але вона не несе відповідальності за будь-які причини, з яких доступ до сайту може бути обмежений.<br /><br />
            <span>Особиста інформація</span><br /><br />
            Реєструючись на сайті, оформляючи замовлення через форму замовлення на сайті або оформляючи замовлення телефоном, а також надаючи нашому магазину будь-яку іншу особисту інформацію будь-яким способом, ви підтверджуєте право на її використання.<br /><br />
            Використання особистої інформації здійснюється виключно для поліпшення якості наших послуг й інформування клієнтів.<br /><br />
            <span>Наш магазин за жодних обставин</span> не передає та не продає вашу особисту інформацію третім особам, за винятком випадків, прямо передбачених законодавством України.<br /><br />
            <span>Користувачеві</span><br /><br />
            Адміністрація сайту залишає за собою право редагувати надані матеріали, повідомлення та особисті дані без попереднього попередження.
            Наш магазин не несе ніякої відповідальності за будь-яку інформацію, розміщену користувачами сайту.<br /><br />
            <span>Звертаючись до нас, залишаючи повідомлення, завантажуючи будь-яку інформацію або дані, ви несете відповідальність</span>, що повідомлення, інформація або дані не є незаконними, шкідливими, а також ніяким іншим чином не порушують законодавство України.<br /><br />
            <span>Забороняється</span><br /><br /> розміщувати або пересилати з цього сайту або на цей сайт будь-які матеріали, які:<br /><br />
            можуть завдати шкоди, мати порнографічний або вульгарний характер, порушити конфіденційність
            створюють або сприяють діям, які будуть вважатися кримінальними, порушувати чи суперечити законодавству або правам третьої сторони
            є технічно небезпечними, включаючи (але не обмежуючись): <br /><br />- комп’ютерні віруси<br />- троянські віруси<br />- небезпечні компоненти<br />- заражені програми або дані
        </p>
        <Footer />
    </div>
  );
};

export default TermsOfUse;