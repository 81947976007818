import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Search from '../../components/js-components/Search';
import Bag from '../../components/js-components/Bag';

import '../../components/css-components/css-components.css';

import LoginIcon from '../../../src/Icon/person.svg';
import ContactIcon from '../../../src/Icon/tel-icon.svg';
import LogoIcon from '../../../src/Icon/logo_icon.svg';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const Nav = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/phone`);
        setPhoneNumber(response.data.number);
      } catch (err) {
        console.error('Failed to fetch phone number', err);
      }
    };
    fetchPhoneNumber();
  }, []);

  const handleLoginClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/cabinet');
    } else {
      navigate('/login');
    }
  };

  return (
    <nav className="nav_container">
      <div className="top_panel">
        <Link className='logo__link' to="/"><img src={LogoIcon} alt="logo" /></Link>
        <div className="catalog_search-item">
          <Link className='catalog__link' to="/catalog">Каталог</Link>
          <Search />
        </div>
        <div className='link_item'>
          <Link className='tel__link' target='blank' to={`tel:${phoneNumber}`}><img src={ContactIcon} /></Link>
          <button className='login__link' onClick={handleLoginClick}>
            <img src={LoginIcon} alt="Login Icon" />
          </button>
          <Bag />
        </div>
      </div>
      <div className="bottom_panel">
        <Link className='catalog__link' to="/catalog">Каталог</Link>
        <Search />
      </div>
    </nav>
  );
};

export default Nav;
