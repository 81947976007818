import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CreateCardForm = () => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [selectedPage, setSelectedPage] = useState('');
  const [podCategories, setPodCategories] = useState([]);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/podcategory`)
      .then(response => {
        setPodCategories(response.data);
      })
      .catch(error => console.error('Error fetching podcategories:', error));
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handlePageChange = (e) => {
    setSelectedPage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!image || !title || !selectedPage) {
      setNotification('Будь ласка, заповніть всі поля.');
      return;
    }

    const formData = new FormData();
    formData.append('image', image);
    formData.append('title', title);
    formData.append('page', selectedPage);

    axios.post(`${baseURL}/api/cardlineproducts`, formData)
      .then(response => {
        setNotification('Картка успішно створена!');
        setImage(null);
        setTitle('');
        setSelectedPage('');
      })
      .catch(error => {
        console.error('Error creating card:', error);
        setNotification('Помилка при створенні картки.');
      });
  };

  return (
    <div>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
      <form className='line_form' onSubmit={handleSubmit}>
        <p>Картки рекомендацій</p>
        <div>
          <label htmlFor="image">Завантажити зображення:</label>
          <input 
            type="file" 
            id="image" 
            onChange={handleImageChange} 
            required 
          />
        </div>
        <div>
          <label htmlFor="title">Назва картки:</label>
          <input 
            type="text" 
            id="title" 
            value={title} 
            onChange={handleTitleChange} 
            required 
          />
        </div>
        <div>
          <label htmlFor="page">Сторінка підкатегорії:</label>
          <select 
            id="page" 
            value={selectedPage} 
            onChange={handlePageChange} 
            required
          >
            <option value="" disabled>Виберіть сторінку</option>
            {podCategories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <button className='card_line-btn' type="submit">Створити картку</button>
      </form>
    </div>
  );
};

export default CreateCardForm;