import React, { useState } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const AdminPhoneInput = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [notification, setNotification] = useState('');

  const handleSave = async () => {
    try {
      await axios.post(`${baseURL}/api/phone`, { number: phoneNumber });
      showNotification('Номер телефону збережено успішно');
    } catch (err) {
      console.error('Failed to save phone number', err);
      showNotification('Не вдалося зберегти номер телефону');
    }
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification('');
    }, 3000);
  };

  return (
    <div className='phone_nav-link'>
      <p>Додати номер телефону до 'хедер'</p>
      <input 
        type="text" 
        value={phoneNumber} 
        onChange={(e) => setPhoneNumber(e.target.value)} 
        placeholder="Номер телефону" 
      />
      <button onClick={handleSave}>Додати</button>

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </div>
  );
};

export default AdminPhoneInput;