import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const EditCardForm = () => {
  const [cards, setCards] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/cardlineproducts`)
      .then(response => setCards(response.data))
      .catch(error => console.error('Error fetching cardline products:', error));
  }, []);

  useEffect(() => {
    if (selectedCardId) {
      const card = cards.find(c => c._id === selectedCardId);
      if (card) {
        setTitle(card.title);
        setImage(card.image);
      }
    }
  }, [selectedCardId, cards]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    if (image) {
      formData.append('image', image);
    }

    axios.patch(`${baseURL}/api/cardlineproducts/${selectedCardId}`, formData)
      .then(response => {
        setNotification('Картка успішно відредагована!');
        setTitle('');
        setImage(null);
        setSelectedCardId('');
      })
      .catch(error => {
        console.error('Error updating card:', error);
        setNotification('Помилка при редагуванні картки.');
      });
  };

  return (
    <div>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
      <form className='line_form' onSubmit={handleSubmit}>
        <div>
          <label htmlFor="card-select">Виберіть картку:</label>
          <select
            id="card-select"
            value={selectedCardId}
            onChange={(e) => setSelectedCardId(e.target.value)}
          >
            <option value="">Виберіть картку</option>
            {cards.map(card => (
              <option key={card._id} value={card._id}>
                {card.title}
              </option>
            ))}
          </select>
        </div>
        {selectedCardId && (
          <>
            <div>
              <label htmlFor="title">Назва картки:</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="image">Зображення:</label>
              <input
                type="file"
                id="image"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <button className='card_line-btn' type="submit">Зберегти зміни</button>
          </>
        )}
      </form>
    </div>
  );
};

export default EditCardForm;