import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const ProductEditForm = () => {
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [productData, setProductData] = useState({
    name: '',
    price: '',
    images: [],
    characteristics: {
      left: [],
      right: []
    },
    path: '',
    availability: 'в наявності'
  });
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/products`);
        setProducts(response.data);
      } catch (error) {
        console.error('Помилка сервера:', error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (selectedProductId) {
        try {
          const response = await axios.get(`${baseURL}/api/products/${selectedProductId}`);
          const product = response.data;
          
          setProductData({
            ...product,
            characteristics: {
              left: product.characteristics?.left || [],
              right: product.characteristics?.right || []
            }
          });
        } catch (error) {
          console.error('Помилка сервера при оновленні характеристик:', error);
        }
      }
    };
    fetchProductDetails();
  }, [selectedProductId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCharacteristicsChange = (e, side, index) => {
    const { value } = e.target;
    setProductData(prevData => {
      const updatedCharacteristics = { ...prevData.characteristics };

      if (side === 'left') {
        updatedCharacteristics.left[index] = value;
      } else if (side === 'right') {
        updatedCharacteristics.right[index] = value;
      }

      return {
        ...prevData,
        characteristics: updatedCharacteristics
      };
    });
  };

  const handleAddCharacteristics = () => {
    setProductData(prevData => ({
      ...prevData,
      characteristics: {
        left: [...prevData.characteristics.left, ''],
        right: [...prevData.characteristics.right, '']
      }
    }));
  };

  const handleImageChange = (e) => {
    setProductData(prevData => ({
      ...prevData,
      images: Array.from(e.target.files)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('name', productData.name);
    formData.append('price', productData.price);
    formData.append('path', productData.path);
    formData.append('availability', productData.availability);
  
    productData.images.forEach((image) => {
      formData.append('images', image);
    });
  
    const characteristics = {
      left: productData.characteristics.left,
      right: productData.characteristics.right
    };
  
    formData.append('characteristics', JSON.stringify(characteristics));
  
    try {
      await axios.put(`${baseURL}/api/products/${selectedProductId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setNotification('Сторінка оновлена успішно!');
      setTimeout(() => setNotification(''), 3000);
    } catch (error) {
      console.error('Помилка сервера при оновленні сторінки:', error);
    }
  };
  
  const handleDelete = async () => {
    if (selectedProductId) {
      try {
        await axios.delete(`${baseURL}/api/products/${selectedProductId}`);
        setProducts(products.filter(product => product._id !== selectedProductId));
        setSelectedProductId('');
        setProductData({
          name: '',
          price: '',
          images: [],
          characteristics: {
            left: [],
            right: []
          },
          path: '',
          availability: 'в наявності'
        });
        setNotification('Сторінка видалена успішно!');
        setTimeout(() => setNotification(''), 3000);
      } catch (error) {
        console.error('Помилка сервера при видаленні:', error);
      }
    }
  };

  return (
    <form className='page_form-adm' onSubmit={handleSubmit}>
      <p className='selected_page-list'>Список сторінок для редагування</p>
      <select
        value={selectedProductId}
        onChange={(e) => setSelectedProductId(e.target.value)}
      >
        <option value="">Оберіть сторінку</option>
        {products.map(product => (
          <option key={product._id} value={product._id}>
            {product.path}
          </option>
        ))}
      </select>

      {selectedProductId && (
        <>
          <p className='title_input-page'>Оновити назву продукту</p>
          <input
            type="text"
            name="name"
            placeholder="Назва продукту"
            value={productData.name}
            onChange={handleInputChange}
            required
          />
          <p className='title_input-page'>Оновити ціну продукту</p>
          <input
            type="number"
            name="price"
            placeholder="Ціна продукту"
            value={productData.price}
            onChange={handleInputChange}
            required
          />
          <p className='title_input-page'>Оновити фото продукту</p>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
          />
          <p className='title_input-page'>Оновити назву сторінки (якщо потрібно)</p>
          <input
            type="text"
            name="path"
            placeholder="Назва сторінки"
            value={productData.path}
            onChange={handleInputChange}
            required
          />

          <p className='title_input-page'>Статус наявності</p>
          <select
            name="availability"
            value={productData.availability}
            onChange={handleInputChange}
            required
          >
            <option value="в наявності">В наявності</option>
            <option value="закінчується">Закінчується</option>
            <option value="закінчилось">Закінчилось</option>
          </select>

          <div>
            <h3 className='title_input-page'>Редагувати характеристики</h3>
            {productData.characteristics.left.map((charLeft, index) => (
              <div className='char_item-col' key={index}>
                <div className="item-col">
                  <p>Назва (наприклад: колір)</p>
                  <input
                    type="text"
                    value={charLeft}
                    onChange={(e) => handleCharacteristicsChange(e, 'left', index)}
                  />
                </div>
                <div className="item-col">
                  <p>Назва (наприклад: синій)</p>
                  <input
                    type="text"
                    value={productData.characteristics.right[index] || ''}
                    onChange={(e) => handleCharacteristicsChange(e, 'right', index)}
                  />
                </div>
              </div>
            ))}
            <button
              className='char_btn'
              type="button"
              onClick={handleAddCharacteristics}
            >
              Додати характеристики
            </button>
          </div>
          <button className='create_page-btn' type="submit">Оновити сторінку</button>
          <button className='delete_page-btn' type="button" onClick={handleDelete}>Видалити сторінку</button>
        </>
      )}

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
    </form>
  );
};

export default ProductEditForm;