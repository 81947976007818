import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const DeleteCard = () => {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');
  const [notification, setNotification] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/cards`)
      .then(response => {
        setCards(response.data);
      })
      .catch(error => console.error(error));
  }, []);

  const handleDelete = async () => {
    try {
      await axios.delete(`${baseURL}/api/cards/${selectedCard}`);
      setCards(cards.filter(card => card._id !== selectedCard));
      setSelectedCard('');
      setNotification('Картку видалено!');
    } catch (error) {
      console.error('Error deleting card:', error);
      setNotification('Помилка видалення картки!');
    }
  };

  return (
    <div className='delete_card'>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
      <p>Видалити картку продуктів</p>
      <select value={selectedCard} onChange={(e) => setSelectedCard(e.target.value)} required>
        <option value="">Виберіть картку для видалення</option>
        {cards.map(card => (
          <option key={card._id} value={card._id}>
            {card.title}
          </option>
        ))}
      </select>
      <button onClick={handleDelete} disabled={!selectedCard}>Видалити картку</button>
    </div>
  );
};

export default DeleteCard;