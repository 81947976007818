import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MainInfo from '../cabinet-tab/MainInfoTab';
import Orders from '../cabinet-tab/OrdersTab';
import Wishlist from '../cabinet-tab/WishlistTab';

const UserTabs = () => {
  const [activeTab, setActiveTab] = useState('mainInfo');
  const history = useNavigate();

  const renderTabContent = () => {
    switch (activeTab) {
      case 'mainInfo':
        return <MainInfo />;
      case 'orders':
        return <Orders />;
      case 'wishlist':
        return <Wishlist />;
      default:
        return null;
    }
  };

  return (
    <div className="user_tabs">
      <div className="tab_user-buttons">
        <button onClick={() => setActiveTab('mainInfo')} className={activeTab === 'mainInfo' ? 'active' : ''}>Основна інформація</button>
        <button onClick={() => setActiveTab('orders')} className={activeTab === 'orders' ? 'active' : ''}>Замовлення</button>
        <button onClick={() => setActiveTab('wishlist')} className={activeTab === 'wishlist' ? 'active' : ''}>Обране</button>
      </div>
      <div className="tab_user-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default UserTabs;