import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Nav from '../components/js-components/Nav';
import Footer from '../components/js-components/Footer';
import { Link } from 'react-router-dom';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const Catalog = () => {
  const [categoryCards, setCategoryCards] = useState([]);

  useEffect(() => {
    axios.get(`${baseURL}/api/category-cards`)
      .then(response => setCategoryCards(response.data))
      .catch(error => console.error('Error fetching category cards:', error));
  }, []);

  return (
    <div className='content_container'>
      <Nav />
      <div className="inner_catalog-container">
        <p className='catalog_title'>Каталог</p>
        <div className="catalog_grid-category">
          {categoryCards.map(card => (
            <div key={card._id} className="card-category">
              <Link to={`/podcategory/${card.link}`}>
                <img src={`${baseURL}${card.imageUrl}`} alt={card.title} />
                <p className="cart_category-title">{card.title}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Catalog;
