import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const BlogCardManager = () => {
  const [image, setImage] = useState(null);
  const [text, setText] = useState('');
  const [selectedPage, setSelectedPage] = useState('');
  const [existingPages, setExistingPages] = useState([]);
  const [existingCards, setExistingCards] = useState([]);
  const [editingCard, setEditingCard] = useState(null);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/blogpages`)
      .then(response => setExistingPages(response.data))
      .catch(error => console.error('Error fetching blog pages:', error));

    axios.get(`${baseURL}/api/blogcards`)
      .then(response => setExistingCards(response.data))
      .catch(error => console.error('Error fetching blog cards:', error));
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('text', text);
    formData.append('pageId', selectedPage);
    if (image) formData.append('image', image);

    try {
      if (editingCard) {
        await axios.put(`${baseURL}/api/blogcards/${editingCard._id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setNotification('Картка блогу успішно оновлена');
      } else {
        await axios.post(`${baseURL}/api/blogcards`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setNotification('Картка блогу успішно створена');
      }

      setText('');
      setImage(null);
      setSelectedPage('');
      setEditingCard(null);
      axios.get(`${baseURL}/api/blogcards`)
        .then(response => setExistingCards(response.data))
        .catch(error => console.error('Error fetching blog cards:', error));
    } catch (error) {
      console.error('Error saving blog card:', error);
      setNotification('Сталася помилка під час збереження картки блогу');
    }

    setTimeout(() => setNotification(''), 3000);
  };

  const handleEditCard = (card) => {
    setText(card.text);
    setImage(card.image);
    setSelectedPage(card.pageId);
    setEditingCard(card);
  };

  const handleDeleteCard = async (cardId) => {
    try {
      await axios.delete(`${baseURL}/api/blogcards/${cardId}`);
      setNotification('Картка блогу успішно видалена');
      setExistingCards(existingCards.filter(card => card._id !== cardId));
    } catch (error) {
      console.error('Error deleting blog card:', error);
      setNotification('Сталася помилка під час видалення картки блогу');
    }

    setTimeout(() => setNotification(''), 3000);
  };

  return (
    <div className='container_create-blog__card'>
      <h2>{editingCard ? 'Редагувати картку блогу' : 'Створити картку блогу'}</h2>
      <form onSubmit={handleSubmit}>
        <p>Завантажити фото</p>
        <input
          type="file"
          onChange={handleImageChange}
        />
        <input
          type="text"
          placeholder="Текст картки"
          value={text}
          onChange={(e) => setText(e.target.value)}
          required
        />
        <p>Підключення картки до сторінки блогу</p>
        <select onChange={(e) => setSelectedPage(e.target.value)} value={selectedPage}>
          <option value="">Виберіть сторінку блогу</option>
          {existingPages.map(page => (
            <option key={page._id} value={page._id}>{page.title}</option>
          ))}
        </select>
        <button className='create_btn-card__blog' type="submit">{editingCard ? 'Оновити картку' : 'Створити картку'}</button>
      </form>

      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}

      <h3>Існуючі картки блогу</h3>
      <div className="existing-cards">
        {existingCards.map(card => (
          <div key={card._id} className="blog-card">
            <img src={`${baseURL}${card.image}`} alt="Blog" />
            <p>{card.text}</p>
            <button onClick={() => handleEditCard(card)}>Редагувати</button>
            <button onClick={() => handleDeleteCard(card._id)}>Видалити</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogCardManager;
