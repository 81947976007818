import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CreatePodCategoryPage = () => {
  const [name, setName] = useState('');
  const [pages, setPages] = useState([]);
  const [catalogCards, setCatalogCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');

  useEffect(() => {
    axios.get(`${baseURL}/api/podcategory`)
      .then(response => setPages(response.data))
      .catch(error => console.error('Error fetching pages:', error));

    axios.get(`${baseURL}/api/category-cards`)
      .then(response => setCatalogCards(response.data))
      .catch(error => console.error('Error fetching catalog cards:', error));
  }, []);

  const handleCreatePage = async () => {
    try {
      const pageResponse = await axios.post(`${baseURL}/api/podcategory`, { name });
      setPages([...pages, pageResponse.data]);

      if (selectedCard) {
        const selectedCardData = catalogCards.find(card => card._id === selectedCard);

        await axios.patch(`${baseURL}/api/category-cards/${selectedCard}`, {
          title: selectedCardData.title,
          imageUrl: selectedCardData.imageUrl,
          link: pageResponse.data._id
        });
      }
      const catalogResponse = await axios.get(`${baseURL}/api/category-cards`);
      setCatalogCards(catalogResponse.data);
    } catch (error) {
      console.error('Error creating page or updating catalog card:', error);
    }
  };

  const handleDeletePage = (id) => {
    axios.delete(`${baseURL}/api/podcategory/${id}`)
      .then(() => setPages(pages.filter(page => page._id !== id)))
      .catch(error => console.error('Error deleting page:', error));
  };

  return (
    <div className='podcategory_form'>
      <h2>Створити сторінку підкатегорії</h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Назва підкатегорії"
      />
      <select
        value={selectedCard}
        onChange={(e) => setSelectedCard(e.target.value)}
      >
        <option value="">Оберіть картку каталогу</option>
        {catalogCards.map(card => (
          <option key={card._id} value={card._id}>
            {card.title}
          </option>
        ))}
      </select>
      <button onClick={handleCreatePage}>Зберегти</button>

      <h3>Існуючі сторінки підкатегорій:</h3>
      <ul>
        {pages.map(page => (
          <li key={page._id}>
            {page.name}
            <button className='delete_pod-page' onClick={() => handleDeletePage(page._id)}>Видалити</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CreatePodCategoryPage;