import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css-components/css-components.css';

const Search = () => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (query.trim()) {
      navigate(`/search-page?query=${encodeURIComponent(query)}`);
    }
  };

  return (
    <nav className="search_container">
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <input
          type="text"
          placeholder="Пошук"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <button type="submit" style={{ display: 'none' }}>Search</button>
      </form>
    </nav>
  );
};

export default Search;