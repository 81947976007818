import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomePage from './page/Homepage';
import Blogpage from './page/Blogpage';
import InnerBlogPage from './InnerBlogPage/InnerBlogPage';
import Login from './page/Login';
import Cabinet from './page/Cabinet';
import Catalog from './page/Catalog';
import AdminPanel from './page/AdminPanel';
import ProtectedRoute from './components/ProtectedRoute';
import PageTemplate from './product-page/PageTemplate';
import BlogPageTemplate from './InnerBlogPage/BlogPageTemplate';
import OrderPage from './product-page/OrderPage';
import ThankYouPage from './product-page/ThankYouPage';
import SearchPage from './components/js-components/SearchPage';

import ProductReturn from './page/ProductReturn';
import PrivacyPolicy from './page/PrivacyPolicy';
import TermsOfUse from './page/TermsOfUse';

import PodCategoryTemplate from './PodCategory/PodCategoryTemplate';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={<Login />} />
        <Route path='/cabinet' element={<ProtectedRoute element={Cabinet} />} />
        <Route path='/catalog' element={<Catalog />} />
        <Route path='/xXinDXOnfLAJBjxxfmaXXsdniGKllbs' element={<AdminPanel />} />
        <Route path='/blog' element={<Blogpage />} />
        <Route path='/blog-page' element={<InnerBlogPage />} />

        <Route path='/povernennya-produktu' element={<ProductReturn />} />
        <Route path='/polityka-konfidentsiynosti' element={<PrivacyPolicy />} />
        <Route path='/umovy-vykorystannya' element={<TermsOfUse />} />

        <Route path='/product/:id' element={<PageTemplate />} />
        <Route path="/blog/:id" element={<BlogPageTemplate />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/thank-you" element={<ThankYouPage />} />

        <Route path="/search-page" element={<SearchPage />} />

        <Route path="/podcategory/:id" element={<PodCategoryTemplate />} />
      </Routes>
    </>
  );
}

export default App;