import React, { useState } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const ProductCreationForm = () => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [images, setImages] = useState([]);
  const [path, setPath] = useState('');
  const [availability, setAvailability] = useState('в наявності');
  const [characteristics, setCharacteristics] = useState([{ left: '', right: '' }]);
  const [notification, setNotification] = useState('');

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleCharacteristicsChange = (index, field, value) => {
    const updatedCharacteristics = [...characteristics];
    updatedCharacteristics[index][field] = value;
    setCharacteristics(updatedCharacteristics);
  };

  const handleAddCharacteristics = () => {
    setCharacteristics([...characteristics, { left: '', right: '' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('price', price);
    formData.append('path', path);
    formData.append('availability', availability);
    formData.append('characteristics', JSON.stringify(characteristics));
    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }

    try {
      await axios.post(`${baseURL}/api/products`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setNotification('Продукт успішно створено!');

      setName('');
      setPrice('');
      setImages([]);
      setPath('');
      setAvailability('в наявності');
      setCharacteristics([{ left: '', right: '' }]);

      setTimeout(() => {
        setNotification('');
      }, 3000);
    } catch (error) {
      console.error('Помилка при створенні продукту:', error);
      setNotification('Помилка при створенні продукту');
      setTimeout(() => {
        setNotification('');
      }, 3000);
    }
  };

  return (
    <form className='page_form-adm' onSubmit={handleSubmit}>
      {notification && (
        <div style={{
          position: 'fixed',
          top: '30px',
          right: '30px',
          background: '#568cfd',
          border: '2px solid #2a69f2',
          color: 'white',
          padding: '10px',
          borderRadius: '5px',
        }}>
          {notification}
        </div>
      )}
      <p className='title_input-page'>Назва продукту</p>
      <input
        type="text"
        placeholder="Назва продукту"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <p className='title_input-page'>Ціна продукту</p>
      <input
        type="number"
        placeholder="Ціна продукту"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        required
      />
      <p className='title_input-page'>Фото продукту (завантажити 2 і більше)</p>
      <input
        type="file"
        multiple
        onChange={handleImageChange}
      />
      <p className='title_input-page'>Назва сторінки</p>
      <input
        type="text"
        placeholder="Назва сторінки"
        value={path}
        onChange={(e) => setPath(e.target.value)}
        required
      />
      <p className='title_input-page'>Статус наявності</p>
      <select
        value={availability}
        onChange={(e) => setAvailability(e.target.value)}
        required
      >
        <option value="в наявності">В наявності</option>
        <option value="закінчується">Закінчується</option>
        <option value="закінчилось">Закінчилось</option>
      </select>
      <div>
        <p className='title_input-page'>Характеристики</p>
        {characteristics.map((char, index) => (
          <div className='char_item-col' key={index}>
            <div className="item-col">
              <p>Назва (наприклад: колір)</p>
              <input
                type="text"
                placeholder="Назва"
                value={char.left}
                onChange={(e) => handleCharacteristicsChange(index, 'left', e.target.value)}
              />
            </div>
            <div className="item-col">
              <p>Назва (наприклад: синій)</p>
              <input
                type="text"
                placeholder="Характеристика"
                value={char.right}
                onChange={(e) => handleCharacteristicsChange(index, 'right', e.target.value)}
              />
            </div>
          </div>
        ))}
        <button className='char_btn' type="button" onClick={handleAddCharacteristics}>Більше характеристик</button>
      </div>
      <button className='create_page-btn' type="submit">Створити сторінку</button>
    </form>
  );
};

export default ProductCreationForm;